import { NavigationConstants } from 'components/navigation/navigationConstants';
import { DeepLinkProps } from 'components/deeplink/deepLinkProps';

export enum DeeplinkIds {
  HOME = '001',
  SEARCH = '002',
  LOCATION = '003',
  RES_SUMMARY = '004',
}

export const getDeeplinkProps = (params: URLSearchParams, pathName: string): DeepLinkProps => {
  return {
    callingApp: params.get(NavigationConstants.CALLING_APP_PARAM) || undefined,
    deeplinkId: params.get(NavigationConstants.DEEP_LINK_ID_PARAM) || undefined,
    backUrl: params.get(NavigationConstants.BACK_URL) || undefined,
    res: params.get(NavigationConstants.RESERVATION_NUMBER) || undefined,
    ra: params.get(NavigationConstants.RENTAL_AGREEMENT_NUMBER) || undefined,
    port: params.get(NavigationConstants.PORT) || undefined,
    returnUrl: params.get(NavigationConstants.RETURN_URL) || undefined,
    locationId: params.get(NavigationConstants.LOCATION_ID) ?? undefined,
    origin: params.get(NavigationConstants.ORIGIN) ?? undefined,
    workflowId: params.get(NavigationConstants.WORKFLOW_ID) ?? undefined,
    pathName: pathName,
  };
};
