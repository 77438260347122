import { fetchQuery, generateQueryKeyWithLocale } from 'components/shared/react-query/queryUtil';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { DOMAIN_CACHE_OPTIONS } from 'components/shared/react-query/cacheOptions';
import { useLocationService } from 'services/location/useLocationService';
import {
  BranchDetails,
  BranchHierarchy,
  BranchV2,
  Countries,
  CountrySubdivision,
} from 'services/location/locationTypes';
import { EhiErrors } from 'services/EhiErrors';
import { LocationAndArea } from 'services/location/locationRollupTypes';
import { useLocationRollUpService } from 'services/location/useLocationRollUpService';
import { QUERY_KEY_LOCATION_AND_AREA } from 'services/location/locationCache';
import { getBranchByPeopleSoftId, getStation } from 'services/location/locationService';

const COUNTRIES_QUERY_KEY = 'countries';
const SUB_DIVISION_QUERY_KEY = 'subDivision';
const MULTI_BRANCH_DETAILS_KEY = 'multiBranchDetails';
const BRANCH_INFO_QUERY_KEY = 'branch';
const BRANCH_HIERARCHY_QUERY_KEY = 'branchHierarchy';
const BRANCH_INFO_BY_URN_QUERY_KEY = 'branchInfoByUrn';
const STATION_INFO_QUERY = 'stationInfo';

export const useCountriesQuery = (): UseQueryResult<Countries, EhiErrors> => {
  const { getCountriesCall } = useLocationService();
  return useQuery(generateQueryKeyWithLocale(COUNTRIES_QUERY_KEY), getCountriesCall, DOMAIN_CACHE_OPTIONS);
};

export const useSubdivisionsQuery = (country: string): UseQueryResult<CountrySubdivision[], EhiErrors> => {
  const { getCountrySubdivisionsCall } = useLocationService();
  return useQuery({
    queryKey: [SUB_DIVISION_QUERY_KEY, country],
    queryFn: () => getCountrySubdivisionsCall(country),
    enabled: !!country,
    ...DOMAIN_CACHE_OPTIONS,
  });
};

export const useMultipleBranchInfoQuery = (locationIds: string[]): UseQueryResult<BranchDetails[], EhiErrors> => {
  const { getMultipleBranchDetailsCall } = useLocationService();

  return useQuery<BranchDetails[], EhiErrors>(
    generateQueryKeyWithLocale([MULTI_BRANCH_DETAILS_KEY, ...(locationIds || [])]),
    () => getMultipleBranchDetailsCall(locationIds),
    { ...DOMAIN_CACHE_OPTIONS, enabled: !!locationIds?.length }
  );
};

export const useBranchInfoByPeopleSoftIdQuery = (locationId: string): UseQueryResult<BranchV2, EhiErrors> => {
  const { getBranchByPeopleSoftIdCall } = useLocationService();
  return useQuery<BranchV2, EhiErrors>(
    generateQueryKeyWithLocale([BRANCH_INFO_QUERY_KEY, locationId]),
    () => getBranchByPeopleSoftIdCall(locationId),
    { ...DOMAIN_CACHE_OPTIONS, enabled: !!locationId }
  );
};

export const useBranchHierarchyByIdQuery = (
  locationId: string,
  isFunctionalHierarchyRequest = false
): UseQueryResult<BranchHierarchy, EhiErrors> => {
  const { getBranchHierarchyCall } = useLocationService();

  return useQuery(
    generateQueryKeyWithLocale([BRANCH_HIERARCHY_QUERY_KEY, isFunctionalHierarchyRequest, locationId]),
    () => {
      return getBranchHierarchyCall(locationId);
    },
    {
      ...DOMAIN_CACHE_OPTIONS,
      enabled: !!locationId,
    }
  );
};

export const useLocationAndAreaQuery = (locationId: string): UseQueryResult<LocationAndArea> => {
  const { getLocationAndArea } = useLocationRollUpService();
  return useQuery([QUERY_KEY_LOCATION_AND_AREA, locationId], () => getLocationAndArea(locationId), {
    ...DOMAIN_CACHE_OPTIONS,
    enabled: !!locationId,
  });
};

export const useBranchInfoByUrnQuery = (urn: string | undefined): UseQueryResult<BranchV2, EhiErrors> => {
  const { getLocationByUrn } = useLocationService();

  return useQuery<BranchV2, EhiErrors>(
    generateQueryKeyWithLocale([BRANCH_INFO_BY_URN_QUERY_KEY, urn]),
    () => getLocationByUrn(urn),
    {
      ...DOMAIN_CACHE_OPTIONS,
      enabled: !!urn,
    }
  );
};

export const fetchBranch = (locationId: string) => {
  return fetchQuery({
    queryKey: generateQueryKeyWithLocale([BRANCH_INFO_QUERY_KEY, locationId]),
    queryFn: () => getBranchByPeopleSoftId(locationId),
    ...DOMAIN_CACHE_OPTIONS,
  });
};

export const fetchStation = (stationId: string) => {
  return fetchQuery({
    queryKey: generateQueryKeyWithLocale([STATION_INFO_QUERY, stationId]),
    queryFn: () => getStation(stationId),
    ...DOMAIN_CACHE_OPTIONS,
  });
};
