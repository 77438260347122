import { RootState } from 'redux/store';
import {
  AddOns,
  Charges,
  Contact,
  Pickup,
  RateSource,
  Renter,
  Reservation,
  Return,
  VehicleClassSelection,
} from 'services/booking/bookingTypes';

/**
 * Try to avoid using this one unless you really need a lot of the data from store at a time.
 * Instead, use other selectors
 */
export const selectReservationData = ({ bookingEditor }: RootState): Reservation | undefined => {
  return bookingEditor.reservation;
};

export const selectBrand = ({ bookingEditor }: RootState): string | undefined => {
  return bookingEditor.reservation?.brand;
};

export const selectCharges = ({ bookingEditor }: RootState): Charges | undefined => {
  return bookingEditor.reservation?.charges;
};

export const selectRenter = ({ bookingEditor }: RootState): Renter | undefined => {
  return bookingEditor.reservation?.renter;
};

export const selectContact = ({ bookingEditor }: RootState): Contact | undefined => {
  return bookingEditor.reservation?.contact;
};

export const selectPickup = ({ bookingEditor }: RootState): Pickup | undefined => {
  return bookingEditor.reservation?.pickup;
};

export const selectReturn = ({ bookingEditor }: RootState): Return | undefined => {
  return bookingEditor.reservation?.return;
};

export const selectRateSource = ({ bookingEditor }: RootState): RateSource | undefined => {
  return bookingEditor.reservation?.rateSource;
};

export const selectVehicleClassSelection = ({ bookingEditor }: RootState): VehicleClassSelection | undefined => {
  return bookingEditor.reservation?.vehicleClassSelection;
};

export const selectAddons = ({ bookingEditor }: RootState): AddOns | undefined => {
  return bookingEditor.reservation?.addOns;
};

export const selectStatusUrn = ({ bookingEditor }: RootState): string | undefined => {
  return bookingEditor.reservation?.status;
};
