import { LocationAndArea } from 'services/location/locationRollupTypes';
import { getCache, removeCache, setCache } from 'components/shared/react-query/queryUtil';

export const QUERY_KEY_LOCATION_AND_AREA = 'locationAndArea';

export function setLocationAndAreaCache(location: LocationAndArea): void {
  setCache([QUERY_KEY_LOCATION_AND_AREA], location);
}

export function getLocationAndAreaCache(locationId: string): LocationAndArea | undefined {
  return getCache([QUERY_KEY_LOCATION_AND_AREA, locationId]);
}

export function removeLocationAndAreaCache(): void {
  removeCache({ queryKey: [QUERY_KEY_LOCATION_AND_AREA] });
}
