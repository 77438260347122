import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios/index';
import {
  ErrorResponseAttributes,
  NetworkHeaders,
  NotApplicable,
  RequestAttributes,
  ResponseAttributes,
} from '@ehi/analytics';
import {
  CONTENT_TYPE,
  EHI_DEVICE_LOCATION_ID,
  EHI_LOCALE,
  EHI_WORKFLOW_ID,
  ORIGIN_APP_VERSION,
  SPAN_ID,
  TRACE_ID,
} from 'services/headerConstants';
import { InternalAxiosRequestConfig } from 'axios';
import {
  buildCustomAttributes,
  isLoggingNetworkRequestAndResponse,
  networkLogManager,
} from 'components/shared/logger/splunkLogger';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { maskBody, maskHeaders } from 'utils/maskUtils';
import { ResponseMessage } from 'services/ResponseMessage';
import { EhiErrors } from 'services/EhiErrors';
import { getSafeLog, logInfoWithStyledMessage } from 'utils/logUtils';

export interface AxiosRequestConfigExtra extends InternalAxiosRequestConfig {
  requestStartTime: number;
  apiName?: string;
  serviceEndpoint?: string;
  genericUrl: string;
}

export const loggingRequestInterceptor = (request: InternalAxiosRequestConfig): AxiosRequestConfigExtra => {
  const genericRequest = request as AxiosRequestConfigExtra;
  genericRequest.requestStartTime = Date.now();
  localLogRequest(genericRequest);

  return genericRequest;
};

export const loggingResponseInterceptor = (response: AxiosResponse): AxiosResponse => {
  localLogResponse(response);
  networkLogManager?.onNetworkSuccess({
    isInteractionLoggingEnabled: isLoggingNetworkRequestAndResponse(),
    customAttributes: { ...buildCustomAttributes() },
    requestDetails: getRequestAttributes(response.config as AxiosRequestConfigExtra),
    responseDetails: getResponseAttributes(response),
  });
  return response;
};

export const loggingErrorInterceptor = async (error: AxiosError): Promise<AxiosError> => {
  localLogErrorResponse(error);

  await networkLogManager?.onNetworkError({
    customAttributes: { ...buildCustomAttributes() },
    requestDetails: getRequestAttributes(error.config as AxiosRequestConfigExtra),
    responseDetails: error.response
      ? getResponseAttributes(error.response)
      : getResponseAttributesFromTimeoutConfig(error.config as AxiosRequestConfig),
    errorDetails: buildNetworkErrorDetails(error),
  });
  return error;
};

export const getRequestAttributes = (config: AxiosRequestConfigExtra): RequestAttributes => {
  const appConfig = getAppConfigCache();
  return {
    url: config.baseURL ? config.baseURL + config.url : 'NO_URL',
    urlGeneric: config.genericUrl,
    httpMethod: config.method ?? 'NO_METHOD',
    ehiLocation: config.headers[EHI_DEVICE_LOCATION_ID],
    ehiLocale: config.headers[EHI_LOCALE],
    spanId: config.headers[SPAN_ID],
    traceId: config.headers[TRACE_ID],
    workflowId: config.headers[EHI_WORKFLOW_ID],
    rawBody: config.rawRequestBody || config.data,
    apiName: config.apiName || NotApplicable,
    apiEnv: config.headers[ORIGIN_APP_VERSION],
    contentType: config.headers[CONTENT_TYPE]?.toString() || NotApplicable,
    timestamp: config.requestStartTime,
    maskedBody: maskBody(config.data, appConfig?.maskingValues.fieldsToMask),
    maskedHeaders: maskHeaders(config.headers, appConfig?.maskingValues.headersToMask),
  };
};

export const getResponseAttributes = (response: AxiosResponse): ResponseAttributes => {
  const requestConfig = response.config;
  const appConfig = getAppConfigCache();
  return {
    rawBody: response.data,
    maskedBody: maskBody(response.data, appConfig?.maskingValues.fieldsToMask),
    maskedHeaders: maskHeaders(response.headers as NetworkHeaders, appConfig?.maskingValues.headersToMask),
    httpStatus: response?.status,
    noResponse: !response.data,
    traceId: requestConfig.headers?.[TRACE_ID],
    timestamp: Date.now(),
  };
};

const getResponseAttributesFromTimeoutConfig = (requestConfig: AxiosRequestConfig): ResponseAttributes => {
  return {
    rawBody: undefined,
    maskedBody: undefined,
    maskedHeaders: undefined,
    noResponse: true,
    httpStatus: undefined,
    traceId: requestConfig.headers?.[TRACE_ID] || '',
    timestamp: Date.now(),
  };
};

const buildNetworkErrorDetails = (error: AxiosError): ErrorResponseAttributes => {
  if (error.response) {
    const { data, statusText, status } = error.response;

    const isErrorArray = Array.isArray(data) && data.length > 0;
    const responseData: any = data;
    const hasMessages = () => responseData?.messages && responseData.messages.length > 0;
    let errorMessage;
    let code;
    if (isErrorArray) {
      errorMessage = data[0]?.supportInfo;
      code = data[0]?.code;
    } else if (hasMessages()) {
      errorMessage = responseData.messages[0]?.message;
      code = responseData.messages[0]?.messageId;
    } else {
      errorMessage = statusText;
      code = error.code;
    }
    const severity = isErrorArray ? data[0]?.severity : '';

    const normalizedError = normalizeErrorResponseData(data, status).errors?.[0];

    return {
      error: normalizedError,
      message: normalizedError?.localizedMessage || errorMessage,
      code: normalizedError?.code || code,
      severity,
    };
  } else {
    return { code: error.code, severity: 'ERROR', error: undefined, message: undefined };
  }
};

const normalizeErrorResponseData = (errorResponseBody: any, httpStatus: number): EhiErrors => {
  let errorData = errorResponseBody;
  if (errorData.errors) {
    errorData = errorResponseBody.errors;
  }

  if (errorData.backendMessages) {
    errorData = errorData.backendMessages;
  }

  if (!Array.isArray(errorData)) {
    errorData = [errorData];
  }

  if (!errorData[0].code || !errorData[0].localizedMessage) {
    errorData = normalizeResponseMessages(httpStatus.toString(), errorData);
  }

  return { status: httpStatus, errors: errorData };
};

const normalizeResponseMessages = (responseStatus: string, errors: any[]): ResponseMessage[] => {
  return errors?.map((it) => {
    if (!it) {
      return {
        code: responseStatus || 'UNKNOWN_ERROR',
        severity: 'ERROR',
      };
    }
    return {
      ...it,
      localizedMessage:
        (it.hasOwnProperty('localizedMessage') && it.localizedMessage) ||
        (it.hasOwnProperty('error') && it.error) ||
        undefined,
      code:
        (it.hasOwnProperty('code') && it.code) ||
        (it.hasOwnProperty('status') && it.status.toString()) ||
        'UNKNOWN_ERROR',
      severity: it.hasOwnProperty('severity') ? it.severity : 'ERROR',
    };
  });
};

const localLogRequest = (request: AxiosRequestConfig): void => {
  // There is a lot of junk we don't care about in the request object,
  // trying to keep it simple
  const requestSummary = {
    method: request.method,
    url: `${request.baseURL}${request.url}`,
    headers: request.headers,
    body: request.data,
  };

  logInfoWithStyledMessage(
    'color: #0084ff;',
    'REQUEST',
    requestSummary.method?.toUpperCase(),
    requestSummary.url,
    getSafeLog(requestSummary)
  );
};

const localLogResponse = (response: AxiosResponse): void => {
  logInfoWithStyledMessage(
    'color: #2f8a41;',
    'RESPONSE',
    response.config.method?.toUpperCase(),
    response.config.url,
    getSafeLog(response)
  );
};

const localLogErrorResponse = (error: AxiosError): void => {
  logInfoWithStyledMessage(
    'color: red;',
    `ERROR RESPONSE ${error.response?.status} ${error.response?.statusText}`,
    error.response?.config.method?.toUpperCase(),
    error.response?.config.url,
    '\n',
    error.response
  );
};
