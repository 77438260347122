import React, { createContext, useContext, useState } from 'react';
import AlertDialog, { AlertDialogProps } from './AlertDialog';

export interface AlertContextProps {
  showAlert: (alert: AlertDialogProps) => void;
}
export const AlertContext = createContext<AlertContextProps>({
  showAlert: () => {
    // do nothing
  },
});

export const useAlert = (): AlertContextProps => useContext(AlertContext);

export const AlertProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [alert, setAlert] = useState<AlertDialogProps | null>(null);
  const showAlert = (alert: AlertDialogProps) => {
    setAlert(alert);
  };
  const hideAlert = () => {
    if (alert) {
      alert.onClose();
      setAlert(null);
    }
  };
  return (
    <AlertContext.Provider value={{ showAlert }}>
      {children}
      {alert && (
        <AlertDialog
          open={alert.open}
          title={alert.title}
          description={alert.description}
          onClose={hideAlert}
          moreMenu={alert.moreMenu}
          primaryActionText={alert.primaryActionText || 'Ok'}
          secondaryActionText={alert.secondaryActionText || 'Cancel'}
          onPrimaryAction={alert.onPrimaryAction}
          onSecondaryAction={alert.onSecondaryAction}></AlertDialog>
      )}
    </AlertContext.Provider>
  );
};
