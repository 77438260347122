import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { RouterPaths } from 'app/router/RouterPaths';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import { LoadableView, LoadingState } from 'components/loadableView/LoadableView';
import { loadEhiLocationCookie } from '@ehi/location';
import { useEhiLocation } from 'hooks/useEhiLocation';
import { useLocationRollUpService } from 'services/location/useLocationRollUpService';
import { isCypressRun } from 'utils/buildInfoUtil';

type LocationGuardProps = {
  children: ReactNode;
};

export const LocationGuard: FC<LocationGuardProps> = ({ children }) => {
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
  const navigate = useNavigate();
  const { validateLocation } = useEhiLocation();
  const { queryLocationAndArea } = useLocationRollUpService();

  useEffectOnlyOnce(async () => {
    try {
      const cookie = loadEhiLocationCookie();
      if (cookie === undefined) {
        setLoadingState(LoadingState.SUCCESS);
        isCypressRun() ? navigate(RouterPaths.SetLocation) : navigate(RouterPaths.Dev);
      } else {
        const cachedLocation = await queryLocationAndArea(cookie.peoplesoftId);

        await validateLocation(cachedLocation);
        setLoadingState(LoadingState.SUCCESS);
      }
    } catch (e) {
      setLoadingState(LoadingState.ERROR);
    }
  });

  return (
    <LoadableView loadingComponent={<FullScreenSpinner />} errorComponent={<ErrorWrapper />} state={loadingState}>
      {children}
    </LoadableView>
  );
};
