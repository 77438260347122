import { FlexRoute } from 'components/shared/flexiFlow/flexRoute';
import { RouterPaths } from 'app/router/RouterPaths';
import { Driver } from 'components/modify/Driver';
import { WhenAndWhere } from 'components/modify/WhenAndWhere';
import { RateAndBilling } from 'components/modify/RateAndBilling';
import { Vehicle } from 'components/modify/Vehicle';
import { AddOns } from 'components/modify/AddOns';
import { Payment } from 'components/modify/Payment';

export const getDefaultFlexRoutes = (): FlexRoute[] => [
  {
    titleKey: 'DRIVER(S)',
    path: RouterPaths.Driver,
    component: Driver,
    position: 0,
  },
  {
    titleKey: 'WHEN & WHERE',
    path: RouterPaths.WhenAndWhere,
    component: WhenAndWhere,
    position: 1,
  },
  {
    titleKey: 'RATE & BILLING',
    path: RouterPaths.RateAndBill,
    component: RateAndBilling,
    position: 2,
  },
  {
    titleKey: 'VEHICLE',
    path: RouterPaths.Vehicle,
    component: Vehicle,
    position: 3,
  },
  {
    titleKey: 'PEO',
    path: RouterPaths.AddOns,
    component: AddOns,
    position: 4,
  },
  {
    titleKey: 'PAYMENT',
    path: RouterPaths.Payment,
    component: Payment,
    position: 5,
  },
];
