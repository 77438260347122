import { useAppDispatch } from 'redux/hooks';
import { Reservation } from 'services/booking/bookingTypes';
import { retrieveReservation } from 'services/booking/bookingService';
import { setReservation } from 'redux/slices/bookingEditorSlice';
import { safelyCatchError } from 'utils/errorUtils';
import { useRenterProfileHelper } from 'services/renter/useRenterProfileHelper';
import { setDriverData } from 'redux/slices/driverSlice';
import { generateWorkflowId } from 'components/shared/preprocessor/ReservationHelper';
import { useAuthStore } from '@ehi/auth';

export const useStartReservationSession = () => {
  const dispatch = useAppDispatch();
  const { getRenterProfile } = useRenterProfileHelper();
  const { eid } = useAuthStore();

  const startViewOnlySession = async (resNumber: string) => {
    let reservationData: Reservation;
    try {
      generateWorkflowId(resNumber, eid);
      reservationData = await retrieveReservation(resNumber);
      dispatch(setReservation({ reservation: reservationData }));
      const driverData = await getRenterProfile(reservationData?.renter, reservationData?.contact);
      if (driverData) {
        dispatch(setDriverData(driverData));
      }
    } catch (error) {
      return { ...safelyCatchError(error) };
    }
  };

  return {
    startViewOnlySession,
  };
};
