import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import { Body1, Body2 } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectContactPhone, selectDriverData, selectRenterFullName } from 'redux/selectors/driver';
import { formatPhoneNumber } from 'utils/phoneNumberUtils';
import { usePhoneTypesQuery } from 'services/renter/renterReferenceQueries';

export const DriversSummary = (): JSX.Element => {
  const { t } = useTranslations();
  const driverData = useAppSelector(selectDriverData);
  const renterFullName = useAppSelector(selectRenterFullName);
  const contactPhone = useAppSelector(selectContactPhone);
  const { data: phoneTypeDomain } = usePhoneTypesQuery();
  const phoneType = phoneTypeDomain?.find((x) => x.code === contactPhone?.type)?.name;

  return (
    <ReservationSummaryCard title={t('reservationSummary.drivers')} loading={false}>
      <CardContent>
        <Grid container direction={'column'}>
          <Grid item xs>
            <Caption2>{t('driver.primary')}</Caption2>
            <Body1 bold>{renterFullName ?? '--'}</Body1>
          </Grid>
          <Grid item xs>
            <Body2>
              {phoneType} {formatPhoneNumber(contactPhone?.number)}
            </Body2>
          </Grid>
          <Grid item xs>
            <Body2> {driverData.email}</Body2>
          </Grid>
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
