import { ChangeEvent, FunctionComponent } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormTextField, FormTextFieldProps } from 'components/shared/forms/FormTextField';

export type MaxLengthTextFieldProps = FormTextFieldProps & { maxLength?: number };

export const MaxLengthTextField: FunctionComponent<MaxLengthTextFieldProps> = ({ maxLength, ...rest }) => {
  const { setValue } = useFormContext();
  return (
    <FormTextField
      {...rest}
      onChange={(value: ChangeEvent<HTMLInputElement>) => {
        if (maxLength) {
          if (value.currentTarget.value?.length <= maxLength) {
            setValue(rest.name, value.currentTarget.value, { shouldDirty: true });
          }
        } else {
          setValue(rest.name, value.currentTarget.value, { shouldDirty: true });
        }
        if (rest.onChange) {
          rest.onChange(value);
        }
      }}
    />
  );
};
