import { ehiTheme, TextType } from '@ehi/ui';
import { FC } from 'react';
import { Text } from 'components/shared/ui/Text';
import { FullHeightContainer, GridAreaContainer, PageContainer } from 'components/shared/ui/styles/Global.styles';
import { ErrorContainer } from 'components/shared/errors/Error.styles';
import { useTranslations } from 'components/shared/i18n/useTranslations';

const ErrorWrapper: FC = () => {
  const { t } = useTranslations();

  return (
    <FullHeightContainer>
      <PageContainer>
        <GridAreaContainer>
          <ErrorContainer data-testid='error-container'>
            <Text fontWeight={400} paddingBottom={ehiTheme.spacing(2)} variant={TextType.H2}>
              {t('error.whoops')}
            </Text>
            <Text variant={TextType.H5}>{t('error.somethingWentWrong')}</Text>
            <Text variant={TextType.H5}>{t('error.contactSupport')}</Text>
            <button onClick={() => window.location.reload()}>{t('error.relaunch')}</button>
          </ErrorContainer>
        </GridAreaContainer>
      </PageContainer>
    </FullHeightContainer>
  );
};

export default ErrorWrapper;
