import { styled } from '@mui/material';
export const SpinnerContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const PageSpinnerContainer = styled(SpinnerContainer)`
  width: 100vw;
`;

export const FullScreenSpinnerContainer = styled(PageSpinnerContainer)`
  height: 100vh;
`;
