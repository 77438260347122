import { Reservation } from 'services/booking/bookingTypes';
import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { EHI_ACCEPT_PROTECTED_DATA, PROTECTED_DATA_VALUE } from 'services/headerConstants';

const ROOT_PATH = '/rental';
const BOOKING_PATH = '/booking';
const RESERVATION_PATH = '/reservation';

export const retrieveReservation = async (reservationNumber: string): Promise<Reservation> => {
  return (
    await getDefaultAxiosInstance().get(`${ROOT_PATH}${BOOKING_PATH}${RESERVATION_PATH}/${reservationNumber}`, {
      genericUrl: `${ROOT_PATH}${BOOKING_PATH}${RESERVATION_PATH}/{reservationNumber}`,
      headers: {
        [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
      },
    })
  ).data;
};
