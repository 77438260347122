import { Body1, Body2 } from '@ehi/ui';
import { Box, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useAppSelector } from 'redux/hooks';
import { selectRateSource } from 'redux/selectors/bookingEditor';
import { RateSource } from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';

export const RateSourceSection = () => {
  const { t } = useTranslations();
  const rateSource: RateSource | undefined = useAppSelector(selectRateSource);
  const accountNumber = parseUrn(rateSource?.type === 'NEGOTIATED' ? rateSource?.account : undefined);
  const rateProduct = parseUrn(rateSource?.rateProduct);
  return (
    <Box>
      <Body1 bold>{t('rateAndBilling.rateSource')}</Body1>
      <Grid container>
        <Grid item xs={6}>
          <Caption2 display={'inline'}>{'Account Number '}</Caption2>
          <Body2 bold display={'inline'}>
            {accountNumber || '--'}
          </Body2>
        </Grid>
        <Grid item xs={6}>
          <Caption2 display={'inline'}>{'Rate Product '}</Caption2>
          <Body2 bold display={'inline'}>
            {rateProduct}
          </Body2>
        </Grid>
      </Grid>
    </Box>
  );
};
