export enum RouterPaths {
  // Admin Endpoints
  Empty = '',
  SupportTicket = '/support',
  Logout = '/logout',
  Error = '/error',
  DeepLink = '/deeplink',

  // Location
  SetLocation = '/set-location',
  SetCounter = '/set-counter',
  AccessDenied = '/no-access',

  // Preprocessor Routes
  PreProcessor = '/res/pre-processor',

  // Sub routes
  BasePath = '/res/:resNumber',
  Home = '/res/home', // Home
  Search = '/res/search', // Search
  Create = '/res/create', // Create
  View = '/res/:resNumber/view', // View
  Modify = '/res/:resNumber/modify', // Modify

  // View/Modify Flex Routes
  WhenAndWhere = 'when-and-where',
  Driver = 'driver',
  RateAndBill = 'rate-and-bill',
  Vehicle = 'vehicle',
  AddOns = 'add-ons',
  Payment = 'payment',

  // dev
  Dev = '/dev',
  SimpleHookForm = '/res/search/form',
  Advanced_Hook_Form = '/res/driver/form',

  // Full Page Res Summary
  ResSummary = '/res/summary',
}

export const SIBLING_PREFIX = '../';
