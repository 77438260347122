import { FunctionComponent } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { Error } from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';

type IconWrapperProps = Partial<SvgIconTypeMap['props']> & {
  component: any;
  className?: string;
};

const IconWrapper: FunctionComponent<IconWrapperProps> = ({ component, className, ...rest }: IconWrapperProps) => {
  return <SvgIcon component={component ?? Error} className={className} {...rest} />;
};

export default IconWrapper;
