export const PHONE_FORMATS: Record<string, string> = {
  US: '(000) 000-0000',
  USA: '(000) 000-0000',
  CA: '(000) 000-0000',
  CAN: '(000) 000-0000',
};
export const DEFAULT_PHONE_FORMAT = '00000000000000';

export const getPhoneFormatForCountry = (country?: string) => {
  if (country && country in PHONE_FORMATS) {
    return PHONE_FORMATS[country];
  }
  return DEFAULT_PHONE_FORMAT;
};

export const NON_DIGITS_REGEX = /\D/g;

export const valueTransform = (displayValue: string) => displayValue.replace(NON_DIGITS_REGEX, '');
