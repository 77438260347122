import { logDebug } from 'utils/logUtils';

const URN_PREFIX = 'urn';
const COM_EHI_NAMESPACE = 'com.ehi';

const verifyUrnString = (urnString: string): string[] => {
  const parts = urnString.split(':');
  if (parts) {
    if (parts[0] !== URN_PREFIX) {
      logDebug(`URN string did not start with 'urn': ${urnString}`);
    } else if (parts[1] !== COM_EHI_NAMESPACE) {
      logDebug(`namespace was not 'com.ehi': ${urnString}`);
    }
  }
  return parts;
};

/**
 * Verifies the urn, then returns the last element in the urn
 * If the urn is invalid, returns nothing
 * @param urn the urn to parse
 */
export const parseUrn = (urn: string | undefined): string => {
  if (urn === undefined || urn === null) return '';
  const parts = verifyUrnString(urn);
  if (parts && parts.length > 1) {
    return parts[parts.length - 1];
  }
  return parts[0];
};

export const parseUrnItem = (urn: string, item: string): string => {
  let returnValue = '';
  if (urn !== undefined && urn !== '' && urn.indexOf(':') !== -1) {
    const valueArray = verifyUrnString(urn);
    let found = false;
    for (const element of valueArray) {
      if (found) {
        returnValue = element;
        break;
      }
      if (item === element) {
        found = true;
      }
    }
  }
  return returnValue;
};
