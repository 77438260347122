import { Params, Path, To } from 'react-router-dom';
import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexiFunctionComponent';
import { RouterPaths } from 'app/router/RouterPaths';

/** Copied from React router useMatches doc */
type RouterMatch = {
  // route id
  id: string;
  // the portion of the URL the route matched
  pathname: string;
  // the data from the loader
  params: Params<string>;
  // the data from the loader
  data: any;
  // the <Route handle> with any app specific data
  handle: any;
};

export type FlexRoute = {
  component?: FlexiFunctionComponent;
  path: RouterPaths;
  titleKey: string;
  // only needed if flex navigation needs to override 'path' (such as android nav)
  to?: Partial<Path>;
  position: number;
  // previous and next are generally not needed, only for outliers that skip around or go outside of flex flow list
  previous?: To;
  next?: To;
  // only set this to true if flex navigation needs to keep the previous path in the history stack so that back navigation can work
  includeBackNavigation?: boolean;
};

// Actual user facing page
export type FlexPage = FlexRoute & {
  component: FlexiFunctionComponent;
};

export const isFlexPage = (route: FlexRoute): route is FlexPage => {
  return route.component !== undefined && route.path !== undefined;
};

const hasFlexDetails = (handle: any): handle is { flexDetails: FlexRoute } => {
  return handle?.flexDetails !== undefined;
};

export const getFlexDetailsFromMatches = (matches: RouterMatch[]): FlexRoute | undefined =>
  matches.find((match) => hasFlexDetails(match?.handle))?.handle?.flexDetails;
