import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexiFunctionComponent';
import { Card } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { Grid, IconButton } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body2 } from '@ehi/ui';
import { ArrowRightAlt } from '@mui/icons-material';
import { useTranslations } from 'components/shared/i18n';

export const WhenAndWhere: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  return (
    <Card>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <CardContent>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Caption2 display={'block'}>{t('whenWhere.rentalStart')}</Caption2>
                <Body2 bold display={'inline'}>
                  {'Mon, 7/12/23 '}
                </Body2>
                <Body2 display={'inline'}>{' 2:30PM'}</Body2>
                <Body2>{'STLS61 ST.Louis Lambert International Airport'}</Body2>
              </Grid>
              <Grid item xs={6}>
                <Caption2 display={'block'}>
                  {t('whenWhere.rentalReturn')}
                  <IconButton style={{ padding: 0 }}>
                    <ArrowRightAlt fontSize='small' color='secondary' />
                  </IconButton>
                </Caption2>
                <Body2 bold display={'inline'}>
                  {'Fri, 8/10/23 '}
                </Body2>
                <Body2 display={'inline'}>
                  {' 2:30PM'} {'28days'}
                </Body2>
                <Body2>{'BNAT61 Nashiville International Airport'}</Body2>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};
