import { convertStringToIso2Locales, EhiCounter, enUS, LocaleItem, localeMap } from '@ehi/location';
import { alpha3ToAlpha2 } from 'i18n-iso-countries';

import { BranchV2 } from 'services/location/locationTypes';
import { ResLocationDetails } from 'services/location/locationRollupTypes';
import { parseUrn } from 'utils/urnUtils';

export function parseBranch(branch: BranchV2, counters?: EhiCounter[]): ResLocationDetails {
  const branchAddress = branch.addresses && branch.addresses.length > 0 ? branch.addresses[0] : undefined;
  const countryIso3 = branchAddress?.country?.code ?? 'USA';

  return {
    stationId: branch.stationId ?? '',
    brand: branch.brand,
    timezone: branch?.timezone,
    groupBranch: branch?.operationalId,
    urn: branch.urn,
    locale: getIso2Locale(countryIso3, branch?.primaryLanguage?.code ?? 'en'),
    id: parseUrn(branch.urn),
    legacyDescription: branch.operationalDescription ?? '',
    countryCode: countryIso3,
    countryIso2: alpha3ToAlpha2(countryIso3) ?? 'US',
    branchType: branch.type,
    counters: counters ?? [],
  };
}

function getIso2Locale(countryIso3: string, primaryLanguage: string): LocaleItem {
  const countryIso2 = alpha3ToAlpha2(countryIso3);
  const iso2Locale = convertStringToIso2Locales(`${primaryLanguage}-${countryIso2}`);
  return localeMap.get(iso2Locale) ?? enUS;
}
