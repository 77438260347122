import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';

const ROOT_PATH = '/bff/rental';
const BOOKING_PATH = 'booking';
const SECRETS_PATH = 'reservation/greenlight/secrets';

export interface Secrets {
  reservationsDevPendoKey: string;
  reservationsDevSupportKey: string;
  reservationsDevLaunchDarklyKey: string;
  reservationsGcsApiKey: string;
}

export const getSecrets = async (): Promise<Secrets> => {
  const secrets = await getDefaultAxiosInstance().get(`${ROOT_PATH}/${BOOKING_PATH}/${SECRETS_PATH}`, {});
  return secrets?.data?.secrets;
};
