import { ActionOptions, EhiAlertDialog } from '@ehi/ui';
import React from 'react';

export type AlertDialogProps = {
  title: string;
  description: string;
  moreMenu?: JSX.Element;
  primaryActionText: string;
  secondaryActionText: string;
  open: boolean;
  // closes the dialog
  onClose: () => void;
  // pass this if primary action is different from closing the dialog
  onPrimaryAction?: () => void;
  // pass this if secondary action is different from closing the dialog
  onSecondaryAction?: () => void;
};
const AlertDialog: React.FC<AlertDialogProps> = ({
  title,
  primaryActionText,
  secondaryActionText,
  open,
  onClose,
  description,
  moreMenu,
  onPrimaryAction,
  onSecondaryAction,
}) => {
  const actions: ActionOptions = {
    primaryAction: {
      label: primaryActionText,
      onClick: () => {
        onPrimaryAction && onPrimaryAction();
        onClose();
      },
    },
    secondaryAction: {
      label: secondaryActionText,
      onClick: () => {
        onSecondaryAction && onSecondaryAction();
        onClose();
      },
    },
  };
  return (
    <EhiAlertDialog
      open={open}
      onClose={onClose}
      title={title}
      description={description}
      titleActions={[moreMenu]}
      actions={actions}></EhiAlertDialog>
  );
};
export default AlertDialog;
