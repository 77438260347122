import { FC, ReactNode } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from 'components/shared/react-query/queryUtil';
import { isDevBuild } from 'utils/buildInfoUtil';

type QueryClientProps = {
  children: ReactNode;
};

export const QueryClient: FC<QueryClientProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {isDevBuild() ? <ReactQueryDevtools initialIsOpen={false} /> : null}
    </QueryClientProvider>
  );
};
