import { FunctionComponent, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { FlexRoute } from 'components/shared/flexiFlow/flexRoute';
import { FlexiFlowMenu } from 'components/shared/flexiFlow/FlexiFlowMenu';
import { InlineReservationSummary } from 'components/resSummary/InlineReservationSummary';
import { ResSummaryDrawerContext } from 'context/ResSummaryDrawerContext';

type FlexiFlowRouterProps = {
  pages: FlexRoute[];
};

export const FlexMenuWrapper: FunctionComponent<FlexiFlowRouterProps> = ({ pages }) => {
  const { openDrawer } = useContext(ResSummaryDrawerContext);
  return (
    <div style={{ display: 'flex' }}>
      {openDrawer && <InlineReservationSummary />}
      <div style={{ flex: '1', padding: '20px' }}>
        <FlexiFlowMenu pages={pages} />
        <Outlet />
      </div>
    </div>
  );
};
