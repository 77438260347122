import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexiFunctionComponent';
import { Card } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { CardHeader, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1 } from '@ehi/ui';

export const Vehicle: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  return (
    <Card>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <CardContent>
        <Card>
          <CardHeader title={t('common.vehicle')} />
          <CardContent>
            <Grid container>
              <Grid item xs={4}>
                <Caption2>{t('vehicle.vehicleClass')}</Caption2>
                <Body1 bold>{'ECONOMY (ECAR)'}</Body1>
              </Grid>
              <Grid item xs={4}>
                <Caption2>{t('vehicle.currentRate')}</Caption2>
                <Body1 bold>{'$38.00/day'}</Body1>
              </Grid>
              <Grid item xs={4}>
                <Caption2>{t('vehicle.distanceRates')}</Caption2>
                <Body1 bold>{'UnLimited'}</Body1>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};
