import { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from '@ehi/auth';
import { initializePendoLogger, setBasePendoMetadata, updatePendoOptions } from '@ehi/analytics';
import { logError } from 'components/shared/logger/splunkLogger';
import { MESSAGE_EVENT_SET_LOCATION } from 'utils/constants';
import { loadEhiLocationCookie } from '@ehi/location';
import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { useBranchInfoByPeopleSoftIdQuery } from 'services/location/locationQueries';
import { extractCountryCode } from 'utils/locationUtils';
import { SecretsContext } from 'context/SecretsContext';

const PendoInitializer: FunctionComponent = () => {
  // The auth component can be used to get the users eid, group branch, and role.
  const auth = useAuthStore();
  const { i18n } = useTranslation();
  const locationId = loadEhiLocationCookie()?.peoplesoftId;
  const { data: branch } = useBranchInfoByPeopleSoftIdQuery(locationId ?? '');
  const locale = i18n.language;
  const {
    secrets: { reservationsDevPendoKey },
  } = useContext(SecretsContext);

  const handleMessage = useCallback(
    async (event: MessageEvent) => {
      if (event.data === MESSAGE_EVENT_SET_LOCATION) {
        updatePendoOptions({
          pendoMetadata: {
            ehiGlobalMetadata: {
              setLocationBranchDivision: branch?.type,
              setLocationCountry: branch?.addresses ? extractCountryCode(branch?.addresses) : '',
              setLocationGroupBranch: branch?.operationalId,
            },
          },
        });
      }
    },
    [branch?.addresses, branch?.operationalId, branch?.type]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    initializePendoLogger(reservationsDevPendoKey, auth.eid, auth.groupBranch, 'deeplinkId');

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [auth, handleMessage, reservationsDevPendoKey]);

  setBasePendoMetadata({
    eid: auth.eid,
    userGroupBranch: auth.groupBranch,
    userRole: auth.role,
    locale: locale,
    locationApiAxiosInstance: getDefaultAxiosInstance(),
    setBranchPeoplesoftId: locationId,
    logError: (e: unknown) => {
      logError(e, 'Error Initializing Pendo!');
    },
  });
  return <></>;
};
export default PendoInitializer;
