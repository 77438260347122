import { DateTime } from 'luxon';

export interface CreateReservationValues {
  lastName: string;
  firstName: string;
  age: number | undefined;
  country: string;
  streetAddress1: string;
  streetAddress2: string;
  state: string;
  city: string;
  postalCode: string;
  contactType: string;
  countryCode: string;
  phone: string;
  email: string;
  startDate: DateTime | undefined;
  startTime: DateTime | undefined;
  returnDate: DateTime | undefined;
  returnTime: DateTime | undefined;
  paymentType: string;
}

export enum CreateReservationFields {
  LastName = 'lastName',
  FirstName = 'firstName',
  Age = 'age',
  Country = 'country',
  StreetAddress1 = 'streetAddress1',
  StreetAddress2 = 'streetAddress2',
  State = 'state',
  City = 'city',
  PostalCode = 'postalCode',
  ContactType = 'contactType',
  CountryCode = 'countryCode',
  Phone = 'phone',
  Email = 'email',
  StartDate = 'startDate',
  StartTime = 'startTime',
  ReturnDate = 'returnDate',
  ReturnTime = 'returnTime',
  PaymentType = 'paymentType',
}
