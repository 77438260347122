import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { useTranslations } from 'components/shared/i18n';

export const RateSourceAndBillToSummary = (): JSX.Element => {
  const { t } = useTranslations();
  // const rateSource = useAppSelector(selectRateSource);

  return (
    <ReservationSummaryCard title={t('reservationSummary.rateSource')} loading={false}>
      <CardContent>
        <Grid container direction={'column'}>
          <Grid item xs>
            <Caption2>{'No rate source selected'}</Caption2>
          </Grid>
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
