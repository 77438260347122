import {
  BranchDetails,
  BranchHierarchy,
  BranchId,
  BranchV2,
  Countries,
  CountrySubdivision,
  Station,
} from 'services/location/locationTypes';
import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';

const ROOT_PATH = '/location';
const COUNTRY_PATH = '/country';
const BRANCH_PATH = '/branch';
const STATION_PATH = '/station';
const HIERARCHY_PATH = '/hierarchy';
export const getCountries = async (): Promise<Countries> => {
  return (
    await getDefaultAxiosInstance().get(`${ROOT_PATH}${COUNTRY_PATH}`, {
      genericUrl: `${ROOT_PATH}${COUNTRY_PATH}`,
    })
  ).data;
};

export const getCountrySubdivisions = async (countryIso3Code: string): Promise<Array<CountrySubdivision>> => {
  return (
    (
      await getDefaultAxiosInstance().get(`${ROOT_PATH}${COUNTRY_PATH}/${countryIso3Code}`, {
        genericUrl: `${ROOT_PATH}${COUNTRY_PATH}/{countryIso3Code}`,
      })
    ).data?.subdivision || []
  );
};

export const getMultipleBranchDetails = async (locationIds: Array<string>): Promise<BranchDetails[]> => {
  const response = await getDefaultAxiosInstance().get<BranchDetails[]>(
    `${ROOT_PATH}${BRANCH_PATH}?ids=${locationIds?.join(',')}`,
    {
      genericUrl: `${ROOT_PATH}${BRANCH_PATH}`,
    }
  );
  return response.data;
};

export const getBranchByPeopleSoftId = async (branchId: BranchId): Promise<BranchV2> => {
  const { data } = await getDefaultAxiosInstance().get(`${ROOT_PATH}${BRANCH_PATH}/${branchId}`, {
    genericUrl: `${ROOT_PATH}${BRANCH_PATH}/{branchId}`,
  });
  return data;
};

export const getStation = async (stationId: string): Promise<Station> => {
  const { data } = await getDefaultAxiosInstance().get(`${ROOT_PATH}${STATION_PATH}/${stationId}`, {
    genericUrl: `${ROOT_PATH}${STATION_PATH}/{locationId}`,
  });

  return data;
};

export const getBranchHierarchy = async (locationId: string): Promise<BranchHierarchy> => {
  const searchParams: URLSearchParams = new URLSearchParams();
  searchParams.append('functionalHierarchy', 'true');
  const { data } = await getDefaultAxiosInstance().get(
    `${ROOT_PATH}${BRANCH_PATH}/${locationId}${HIERARCHY_PATH}?`.concat(searchParams.toString()),
    {
      genericUrl: `${ROOT_PATH}${BRANCH_PATH}/{locationId}${HIERARCHY_PATH}`,
    }
  );
  return data;
};
