import React, { Dispatch, SetStateAction, createContext, useState } from 'react';
interface ResSummaryDrawerContextProps {
  openDrawer: boolean;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
}
export const ResSummaryDrawerContext = createContext<ResSummaryDrawerContextProps>({
  openDrawer: false,
  setOpenDrawer: () => undefined,
});
export const ResSummaryDrawerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <ResSummaryDrawerContext.Provider value={{ openDrawer, setOpenDrawer }}>
      {children}
    </ResSummaryDrawerContext.Provider>
  );
};
