import { FC } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useTranslations } from 'components/shared/i18n';
import { CreateReservationFields, CreateReservationValues } from 'components/create/CreateReservationTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { Button } from 'components/shared/ui/Button';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { DatePickerField } from 'components/shared/forms/DatePickerField';
import { TimePickerField } from 'components/shared/forms/TimePickerField';
import { SelectField } from 'components/shared/forms/SelectField';
import { Divider } from '@mui/material';
import { NewReservationContainer } from 'components/create/CreateReservation.styles';
import { EhiButton } from '@ehi/ui';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import * as Yup from 'yup';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';
import { useNavigate } from 'react-router-dom';
import { RouterPaths } from 'app/router/RouterPaths';
import { loadEhiLocationCookie } from '@ehi/location';
import DynamicHelmet from 'components/shared/helmet/DynamicHelmet';
import { useBranchInfoByPeopleSoftIdQuery } from 'services/location/locationQueries';
import { extractCountryCode } from 'utils/locationUtils';

export const CreateReservation: FC = () => {
  const navigate = useNavigate();
  const handleSubmit = (data: CreateReservationValues) => {
    console.log('submitted form data', data);
    navigate(RouterPaths.Dev);
  };
  const handleCancel = () => {
    navigate(RouterPaths.Dev);
  };

  const { t } = useTranslations();

  const validationSchema = Yup.object().shape({
    lastName: Yup.string().required(t('validation.requiredField')),
    firstName: Yup.string().required(t('validation.requiredField')),
    age: Yup.number()
      .positive('Age must be a positive number')
      .integer('Age must be an integer')
      .required(t('validation.requiredField')),
    country: Yup.string().required(t('validation.requiredField')),
    streetAddress1: Yup.string().required(t('validation.requiredField')),
    streetAddress2: Yup.string(),
    state: Yup.string().required(t('validation.requiredField')),
    city: Yup.string().required(t('validation.requiredField')),
    postalCode: Yup.string().required(t('validation.requiredField')),
    contactType: Yup.string().required(t('validation.requiredField')),
    countryCode: Yup.string().required(t('validation.requiredField')),
    phone: Yup.number().typeError('Phone must be a number').required(t('validation.requiredField')),
    email: Yup.string().email('Invalid email format').required(t('validation.requiredField')),
    startDate: Yup.date()
      .required(t('validation.requiredField'))
      .nullable()
      .typeError('Date must be in format MM/DD/YYYY'),
    startTime: Yup.string().required(t('validation.requiredField')), // Assuming time is a string, adjust if it's a Date object
    returnDate: Yup.date()
      .required(t('validation.requiredField'))
      .nullable()
      .typeError('Date must be in format MM/DD/YYYY'),
    returnTime: Yup.string().required(t('validation.requiredField')), // Adjust based on actual data type
    paymentType: Yup.string().required(t('validation.requiredField')),
  });

  const initialValues: CreateReservationValues = {
    lastName: '',
    firstName: '',
    age: undefined,
    country: '',
    streetAddress1: '',
    streetAddress2: '',
    state: '',
    city: '',
    postalCode: '',
    contactType: '',
    countryCode: '',
    phone: '',
    email: '',
    startDate: undefined,
    startTime: undefined,
    returnDate: undefined,
    returnTime: undefined,
    paymentType: '',
  };
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm<CreateReservationValues>({ resolver: resolver, defaultValues: initialValues });
  const onFormSubmit = methods.handleSubmit(handleSubmit);

  return (
    <NewReservationContainer>
      <DynamicHelmet title={t('common.createReservation')} />
      <FormProvider {...methods}>
        <CreateReservationFormContainer loading={false} onFormSubmit={onFormSubmit} handleCancel={handleCancel} />
      </FormProvider>
    </NewReservationContainer>
  );
};

type CreateReservationContainerProps = {
  loading?: boolean;
  onFormSubmit: () => void;
  handleCancel: () => void;
};

const CreateReservationFormContainer: FC<CreateReservationContainerProps> = ({ onFormSubmit, handleCancel }) => {
  const { t } = useTranslations();
  const cookie = loadEhiLocationCookie();
  const { setValue } = useFormContext();
  const { data: branchV2 } = useBranchInfoByPeopleSoftIdQuery(cookie?.peoplesoftId ?? '');

  return (
    <GridContainer style={{ padding: '0rem 2rem' }}>
      <GridContainer>
        <h2>New Reservation</h2>
      </GridContainer>
      <GridContainer>
        <GridItem sm={12}>
          <h3>Driver</h3>
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={CreateReservationFields.LastName} label={t('common.lastName')}></FormTextField>
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={CreateReservationFields.FirstName} label={t('common.firstName')}></FormTextField>
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={CreateReservationFields.Age} label={'Age'}></FormTextField>
        </GridItem>
      </GridContainer>
      <Divider />
      <GridContainer>
        <GridItem sm={12}>
          <h3>Address</h3>
        </GridItem>
        <GridItem sm={4}>
          <CountrySelectField
            name={CreateReservationFields.Country}
            required
            label={'Country'}
            onChange={(event) => {
              setValue(CreateReservationFields.Country, event.target.value);
              setValue(CreateReservationFields.State, '');
            }}
          />
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={CreateReservationFields.StreetAddress1} label={'Street Address1'}></FormTextField>
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={CreateReservationFields.StreetAddress2} label={'Street Address2'}></FormTextField>
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={CreateReservationFields.City} label={'City'}></FormTextField>
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={CreateReservationFields.State} label={'State'}></FormTextField>
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={CreateReservationFields.PostalCode} label={'Postal Code'}></FormTextField>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={12}>
          <h3>Contact</h3>
        </GridItem>
        <GridItem sm={4}>
          <SelectField
            name={CreateReservationFields.ContactType}
            label={t('Contact Type')}
            options={[{ label: 'Test', value: 'test' }]}
            hasNoneOption={false}
          />
        </GridItem>
        <GridItem sm={4}>
          <SelectField
            name={CreateReservationFields.CountryCode}
            label={t('Country Code')}
            options={[{ label: 'Test', value: 'test' }]}
            hasNoneOption={false}
          />
        </GridItem>
        <GridItem sm={4}>
          <PhoneTextField
            country={extractCountryCode(branchV2?.addresses)}
            name={CreateReservationFields.Phone}
            label={'Phone'}
          />
        </GridItem>
        <GridItem sm={6}>
          <FormTextField name={CreateReservationFields.Email} label={'Email'}></FormTextField>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={12}>
          <h3>Rental Start</h3>
        </GridItem>
        <GridItem sm={6}>
          <DatePickerField name={CreateReservationFields.StartDate} label={t('Start Date')} />
        </GridItem>
        <GridItem sm={6}>
          <TimePickerField name={CreateReservationFields.StartTime} label={t('Start Time')} />
        </GridItem>
        <GridItem sm={12} style={{ display: 'flex' }}>
          <h5 style={{ color: 'grey' }}> Start Location</h5>
          <EhiButton onClick={() => window.alert('Enter start location')}>Edit</EhiButton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={12}>
          <h3>Rental Return</h3>
        </GridItem>
        <GridItem sm={6}>
          <DatePickerField name={CreateReservationFields.ReturnDate} />
        </GridItem>
        <GridItem sm={6}>
          <TimePickerField name={CreateReservationFields.ReturnTime} />
        </GridItem>
        <GridItem sm={12} style={{ display: 'flex' }}>
          <h5 style={{ color: 'grey' }}> Return Location</h5>
          <EhiButton onClick={() => window.alert('Enter return location')}>Edit</EhiButton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={12}>
          <h3>Vehicle</h3>
        </GridItem>
        <GridItem sm={12} style={{ display: 'flex' }}>
          <h5 style={{ color: 'grey' }}> Vehicle Class</h5>
          <EhiButton onClick={() => window.alert('Enter vehicle class')}>Add</EhiButton>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem sm={12}>
          <h3>Payment</h3>
        </GridItem>
        <GridItem sm={4}>
          <SelectField
            name={CreateReservationFields.PaymentType}
            label={t('Payment Type')}
            options={[{ label: 'Test', value: 'test' }]}
            hasNoneOption={false}
          />
        </GridItem>
      </GridContainer>

      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Button onClick={handleCancel}>CANCEL</Button>
        <div>
          <Button>CREATE AND CONTINUE</Button>
          <Button onClick={onFormSubmit}>CREATE AND EXIT</Button>
        </div>
      </div>
    </GridContainer>
  );
};
