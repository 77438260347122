import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Reservation } from 'services/booking/bookingTypes';

export interface ReservationState {
  editorId?: string;
  reservation?: Reservation;
}

const initialState: ReservationState = {
  editorId: undefined,
  reservation: undefined,
};

export type FromRetrieve = {
  reservation: Reservation;
  editor?: never;
};

const bookingEditorSlice = createSlice({
  name: 'bookingEditor',
  initialState: initialState,
  reducers: {
    setReservation: (state: ReservationState, action: PayloadAction<FromRetrieve>) => {
      state.reservation = action.payload.reservation;
    },
    // use to store editorId
    setBookingEditorId: (state: ReservationState, action: PayloadAction<string>) => {
      state.editorId = action.payload;
    },
  },
});

export const { setReservation, setBookingEditorId } = bookingEditorSlice.actions;

export default bookingEditorSlice.reducer;
