import { FC, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { LoadableView, LoadingState } from 'components/loadableView/LoadableView';
import { logError } from 'components/shared/logger/splunkLogger';
import { useEffectOnlyOnce } from 'hooks/useEffectOnlyonce';
import { FullScreenSpinner } from 'components/shared/ui/spinner/FullScreenSpinner';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { useQueryParamsByKey } from 'utils/routing/routeUtils';
import { InternalTransactionParams } from 'utils/routing/InternalTransactionParams';
import { TransactionTypes } from 'utils/routing/TransactionTypes';
import { RouterPaths } from 'app/router/RouterPaths';
import { useStartReservationSession } from 'components/shared/preprocessor/useStartReservationSession';

export const ReservationPreprocessor: FC = () => {
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
  const { startViewOnlySession } = useStartReservationSession();

  const queryParams = useQueryParamsByKey([InternalTransactionParams.Res, InternalTransactionParams.TransactionType]);
  const { [InternalTransactionParams.Res]: resNumber, [InternalTransactionParams.TransactionType]: transactionType } =
    queryParams;

  const viewReservation = async () => {
    try {
      await startViewOnlySession(resNumber);
      setLoadingState(LoadingState.SUCCESS);
      navigate(
        {
          pathname: `/res/${resNumber}/view/driver`,
        },
        {
          replace: true,
        }
      );
    } catch (error) {
      logError({
        error: error,
        message: 'Error retrieving reservation data',
      });
      setLoadingState(LoadingState.ERROR);
    }
  };

  const processReservations = async () => {
    switch (transactionType) {
      case TransactionTypes.View:
        viewReservation();
        break;
      case TransactionTypes.Modify:
        // Future flow
        console.log('create booking editor session here to modify:', resNumber);
        navigate(`/res/${resNumber}/modify`);
        break;
      default:
        logError({ message: 'Invalid Transaction Type' });
        navigate(RouterPaths.Dev);
    }
  };

  useEffectOnlyOnce(() => {
    processReservations();
  });

  return (
    <LoadableView loadingComponent={<FullScreenSpinner />} errorComponent={<ErrorWrapper />} state={loadingState}>
      <Outlet />
    </LoadableView>
  );
};
