import { Box, styled } from '@mui/material';
import { Card } from 'components/shared/ui/card/Card';

export const ReservationSummaryCardContainer = styled('div')`
  display: flex;
  margin-right: ${(props) => props.theme.spacing(2)};

  ${(props) => props.theme.breakpoints.up('md')} {
    flex-direction: row;
  }
  ${(props) => props.theme.breakpoints.down('lg')} {
    flex-direction: column;
  }
`;

export const Section = styled('div')`
  flex: 1 1 0;
`;

export const SectionContent = styled('div')`
  flex: 2 1 auto;
`;

export const SummaryCardStyled = styled(Card)(({ theme }) => ({
  minHeight: 'unset',
  '& .MuiCardHeader-title': {
    h6: {
      color: theme.palette.primary.main,
    },
  },
}));

export const ReservationSummaryBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing(4)};
`;
