export enum RenterTypes {
  NoProfile = 'NO_PROFILE',
  DriverProfile = 'DRIVER_PROFILE',
  TransactionalProfile = 'TRANSACTIONAL_PROFILE',
}

export interface Address {
  lines: string[];
  city: string;
  countrySubdivisionCode?: string;
  countryCode?: string;
  postalCode: string;
}

export interface Phone {
  number?: string;
  extension?: string;
  type?: string;
  priority?: number;
}
export interface Contact {
  // Note: contact name might be different from renter in No_profile scenario
  name?: string;
}

export interface DriverData {
  type?: string;
  firstName?: string;
  lastName?: string;
  age?: number;
  contact?: Contact;
  phone?: Phone;
  email?: string;
  address?: Address;
}
