import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexiFunctionComponent';
import { Card } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { DividerWithFullWidth } from 'components/shared/ui/styles/Divider.styles';
import { RateSourceSection } from './rateAndBillingSections/RateSourceSection';
import { BillToSection } from './rateAndBillingSections/BillToSection';
import { VoucherSection } from './rateAndBillingSections/VoucherSection';
import { VehicleShopSection } from './rateAndBillingSections/VehicleShopSection';
import { CouponSection } from './rateAndBillingSections/CouponSection';
import { AdditionalInfoSection } from './rateAndBillingSections/AdditionalInfoSection';

export const RateAndBilling: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  return (
    <Card>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <CardContent>
        <Card>
          <CardContent>
            <RateSourceSection />
            <DividerWithFullWidth />
            <BillToSection />
            <DividerWithFullWidth />
            <VehicleShopSection />
            <DividerWithFullWidth />
            <VoucherSection />
            <DividerWithFullWidth />
            <CouponSection />
            <DividerWithFullWidth />
            <AdditionalInfoSection />
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};
