import { MenuItem } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { useTranslations } from 'components/shared/i18n';
import { Option } from 'components/shared/ui/Option';
import { FormTextField, FormTextFieldProps } from 'components/shared/forms/FormTextField';

export type SelectFieldProps<TOptions = unknown> = FormTextFieldProps & {
  options: Option<TOptions>[];
  hasNoneOption?: boolean;
  hasAllOption?: boolean;
  formControlProps?: Record<string, any>;
};

export const ALL_VALUE = 'all' as const;

export const SelectField: FunctionComponent<SelectFieldProps> = ({
  options,
  hasNoneOption,
  hasAllOption,
  ...props
}) => {
  const { t } = useTranslations();
  const [open, setOpen] = useState(false);
  const [shouldOpen, setShouldOpen] = useState(true);

  const handleBlur = () => {
    setShouldOpen(true);
  };

  const handleFocus = () => {
    if (!open && shouldOpen) {
      handleOpen();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShouldOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormTextField
      {...props}
      select
      SelectProps={{
        open: open,
        onClose: handleClose,
        onOpen: handleOpen,
        onFocus: handleFocus,
        onBlur: handleBlur,
      }}>
      {hasNoneOption && (
        <MenuItem disableRipple value=''>
          <em>{t('common.none')}</em>
        </MenuItem>
      )}
      {hasAllOption && (
        <MenuItem disableRipple value={ALL_VALUE}>
          <em>{t('common.all')}</em>
        </MenuItem>
      )}
      {getOptions(options)}
    </FormTextField>
  );
};

const getOptions = (options: Option[]) => {
  return options.map((option) => {
    return (
      <MenuItem disableRipple value={option.value} key={option.value}>
        {option.label}
      </MenuItem>
    );
  });
};
