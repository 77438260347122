import { FC, ReactNode } from 'react';

type LoadableViewProps = {
  children: ReactNode;
  loadingComponent: JSX.Element;
  errorComponent: JSX.Element;
  state: LoadingState;
};

export enum LoadingState {
  LOADING,
  SUCCESS,
  ERROR,
}

export const LoadableView: FC<LoadableViewProps> = ({ errorComponent, loadingComponent, children, state }) => {
  switch (state) {
    case LoadingState.LOADING:
      return loadingComponent;
    case LoadingState.SUCCESS:
      return <>{children}</>;
    case LoadingState.ERROR:
      return errorComponent;
  }
};
