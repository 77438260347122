import { TextFieldProps } from '@mui/material';
import useFieldFormatter, { formatString } from 'format-as-you-type';
import { useEffectWhen } from 'hooks/useEffectWhen';
import { FunctionComponent, useState } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { EhiTextField } from 'components/shared/forms/EhiTextField';
import { FieldClearIcon } from 'components/shared/ui/FieldClearIcon';
import { InputIconButton } from 'components/shared/ui/InputIconButton/InputIconButton';
import { getPhoneFormatForCountry, valueTransform } from 'components/shared/forms/fomats';

type PhoneTextFieldProps = TextFieldProps & {
  name: string;
  country?: string;
  allowClearing?: boolean;
};

/**
 * A re-usable text input that supports phone number formatting. These inputs will automatically
 * format the value that is visible on the text field but will update the form state with the
 * value that has no formatting whatsoever. For example, while the user will see (999) 888-9999
 * the form value will equal 9998889999 because in most cases APIs and data stores do not want
 * any formatted values, they prefer the raw values.
 *
 * @param country {string} ISO2 or ISO3 country code used to select phone format
 * @param allowClearing
 * @param props
 */
export const PhoneTextField: FunctionComponent<PhoneTextFieldProps> = ({
  country,
  allowClearing = false,
  ...props
}) => {
  const { control } = useFormContext();
  const format = getPhoneFormatForCountry(country);

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field, fieldState }) => (
        <FormattedTextField
          field={field}
          fieldState={fieldState}
          format={format}
          valueTransform={valueTransform}
          allowClearing={allowClearing}
          {...props}
        />
      )}
    />
  );
};

type FormattedTextFieldProps = TextFieldProps & {
  format: string;
  valueTransform?: (displayValue: string) => string;
  allowClearing: boolean;
  field: ControllerRenderProps;
  fieldState: ControllerFieldState;
};

const FormattedTextField: FunctionComponent<FormattedTextFieldProps> = ({
  format,
  valueTransform,
  allowClearing,
  field,
  fieldState,
  ...rest
}) => {
  const { setValue, setFocus } = useFormContext();
  const [displayValue, setDisplayValue] = useState(field.value ? formatString(field.value, format) : field.value);

  const formatterProps = useFieldFormatter(
    (newInput: string, options: any) => formatString(newInput, format, options),
    (newInput: string) => {
      setDisplayValue(newInput);
      setValue(field.name, valueTransform ? valueTransform(newInput) : newInput);
    },
    'inputRef'
  );

  useEffectWhen(() => {
    setDisplayValue(formatString(field.value, format));
  }, displayValue !== formatString(field.value, format));

  return (
    <EhiTextField
      {...rest}
      {...formatterProps}
      inputRef={field.ref}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      value={displayValue}
      type={'tel'}
      inputProps={{ 'data-testid': field.name, 'aria-label': field.name }}
      InputProps={{
        ...rest.InputProps,
        endAdornment: (
          <>
            {allowClearing && (
              <InputIconButton
                onClick={() => {
                  setDisplayValue('');
                  setValue(field.name, '');
                  setFocus(field.name);
                }}
                icon={<FieldClearIcon />}
              />
            )}
            {rest.InputProps?.endAdornment}
          </>
        ),
      }}
    />
  );
};
