import { DeepLinkProps } from 'components/deeplink/deepLinkProps';
import { SESSION_CACHE_OPTIONS } from 'components/shared/react-query/cacheOptions';
import { getCache, removeCache, setCache, updateCacheOptions } from 'components/shared/react-query/queryUtil';

const NAVIGATION_QUERY_KEY = ['navigation'];

export function removeNavigationCache(): void {
  removeCache({ queryKey: NAVIGATION_QUERY_KEY });
}

/**
 * This cache is used to hold on to DeeplinkProps around different ways to get back to a "source"
 * application. BackUrl and ReturnUrl are examples where a user can be taking numerous actions
 * going screen to screen, but we still need back arrow to return to the source (backUrl) and after a successful
 * transaction, to use the returnUrl
 * @param navigation
 */
export function setNavigationCache(navigation: DeepLinkProps): void {
  updateCacheOptions(NAVIGATION_QUERY_KEY, { ...SESSION_CACHE_OPTIONS });
  setCache(NAVIGATION_QUERY_KEY, navigation);
}

/**
 * This cache is used to hold on to DeeplinkProps around different ways to get back to a "source"
 * application. BackUrl and ReturnUrl are examples where a user can be taking numerous actions
 * going screen to screen, but we still need back arrow to return to the source (backUrl) and after a successful
 * transaction, to use the returnUrl
 */
export function getNavigationCache(): DeepLinkProps | undefined {
  return getCache(NAVIGATION_QUERY_KEY);
}
