import { useState } from 'react';
import { EhiButton } from '@ehi/ui';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ActionsContainer, CounterLabel, CounterOptions, CounterTitle, SubTitle } from './SetCounter.styles';
import { useTranslations } from 'components/shared/i18n';
import { EhiCounter } from '@ehi/location';
import { PrimaryButton } from 'components/shared/ui/styles/Global.styles';

export type SetCounterFormProps = {
  onCancel: () => void;
  onSubmit: (counter: EhiCounter) => void;
  counters: EhiCounter[];
  defaultCounter?: EhiCounter;
};

export const SetCounterForm = ({ counters, onSubmit, onCancel, defaultCounter }: SetCounterFormProps): JSX.Element => {
  const { t } = useTranslations();
  const [counter, setCounter] = useState<EhiCounter>(defaultCounter ?? counters[0]);

  const handleChange = (event: SelectChangeEvent<string>) => {
    const counter = counters.find((counter: EhiCounter) => counter.counterId === event.target.value);
    if (counter) {
      setCounter(counter);
    } else {
      throw Error('Counter was not found in list');
    }
  };

  return (
    <>
      <CounterTitle data-testid={'counter'}>{t('counter.setCounter')}</CounterTitle>
      <SubTitle data-testid={'counterHelperText'}>{t('counter.counterHelperText')}</SubTitle>
      <CounterOptions
        onKeyDown={(event: any) => {
          if (event.keyCode === 13) {
            onSubmit(counter);
          }
        }}>
        <CounterLabel>{t('counter.counter')}</CounterLabel>
        <Select
          data-testid={'selectCounter'}
          label={t('counter.counter')}
          MenuProps={{
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
          }}
          value={counter?.counterId ?? ''}
          renderValue={() => counter?.description}
          labelId='selectCounter'
          onChange={handleChange}
          autoFocus>
          {counters.map((counter, index) => (
            <MenuItem data-testid={'CounterMenu-' + index} key={index} value={counter.counterId}>
              {counter.description}
            </MenuItem>
          ))}
        </Select>
      </CounterOptions>
      <ActionsContainer>
        <EhiButton
          onClick={() => {
            onCancel();
          }}
          data-testid={'cancel-button'}>
          {t('common.cancel')}
        </EhiButton>
        <PrimaryButton
          primary
          onClick={() => {
            onSubmit(counter);
          }}
          data-testid={'setCounter-button'}>
          {t('counter.setCounter')}
        </PrimaryButton>
      </ActionsContainer>
    </>
  );
};
