export const CALLING_APP_ID = 12;
export const EMPTY = '';
export const SPACE = ' ';
export const SEPERATOR = ',';

export enum PrimaryAppBarAction {
  MENU = 'MENU',
  BACK = 'BACK',
}

// Custom Events
export const MESSAGE_EVENT_SET_LOCATION = 'locationSet';

export enum Brand {
  ENTERPRISE = 'ENTERPRISE',
  ALAMO = 'ALAMO',
  NATIONAL = 'NATIONAL',
  ATESA = 'ATESA',
  CITER = 'CITER',
  ET = 'ET',
  AL = 'AL',
  ZL = 'ZL',
}
