import {
  BatchableSplunkLogger,
  createBatchableSplunkLogger,
  ErrorSeverity,
  NetworkLogManager,
  SplunkAttributes,
} from '@ehi/analytics';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { isDevBuild } from 'utils/buildInfoUtil';
import { getScreen, parseUserAgent } from 'utils/deviceInfo';
import { LogEventName } from 'components/shared/logger/loggingConstants';
import { authStore } from '@ehi/auth';
import { detectDevice } from 'utils/deviceDetectionUtils';
import { parseGroup } from 'utils/locationUtils';
import { loadEhiLocationCookie } from '@ehi/location';
import { getLocationAndAreaCache } from 'services/location/locationCache';

export let splunkLogger: BatchableSplunkLogger;
export let networkLogManager: NetworkLogManager;

const BEFORE_UNLOAD = 'beforeunload';
const DEFAULT_INTERVAL_TIME = 1000; // 1 second in milliseconds

export type ExceptionAttributes = {
  exception: any;
};

export function createSplunkLogger() {
  const { jwt } = authStore.getSnapshot();
  const appConfig = getAppConfigCache();
  const getLoggerUrlContext = (): string => {
    let returnValue = '';
    appConfig?.serviceEndpoints?.superEdge?.contextApis.forEach(function (contextApi) {
      if (contextApi.name === 'Logger API') {
        returnValue = contextApi.context;
      }
    });
    return returnValue;
  };

  splunkLogger = createBatchableSplunkLogger({
    isDevBuild: isDevBuild(),
    url: `${appConfig?.serviceEndpoints?.superEdge?.url}${getLoggerUrlContext()}`,
    token: jwt,
    authorizationType: 'Bearer',
    defaultAttributes: buildDefaultAttributes(),
    host: window.location.host,
    batchOptions: {
      interval: DEFAULT_INTERVAL_TIME,
    },
  });
  networkLogManager = new NetworkLogManager({ splunkLogger });
}

export function buildDefaultAttributes(): SplunkAttributes {
  const appConfig = getAppConfigCache();
  const cookie = loadEhiLocationCookie();
  const location = getLocationAndAreaCache(cookie?.stationId ?? '')?.location;
  const locale = location?.locale;
  const groupBranch = location?.groupBranch;
  const { browser } = parseUserAgent();
  const { height, width, orientation } = getScreen();
  const { deviceType } = detectDevice();
  const snapshot = authStore.getSnapshot();

  return {
    accessedBy: deviceType,
    hostApplication: APP_NAME,
    appVersion: APP_VERSION,
    browser: browser.name,
    browserVersion: browser.version,
    country: locale?.iso3Country,
    groupBranch: groupBranch,
    group: groupBranch ?? parseGroup(groupBranch),
    locale: locale?.iso3Locale,
    language: locale?.iso2Language,
    peopleSoftId: location?.id,
    role: snapshot.role,
    stationId: location?.stationId,
    screenHeight: height.toString(),
    screenWidth: width.toString(),
    screenOrientation: orientation.type,
    timezone: location?.timezone,
    userId: snapshot.eid,
    webEnvironment: appConfig?.webEnvironment,
  };
}

export function logExternalAppNavigation(outgoingApplication: string) {
  const customAttributes = {
    ...buildCustomAttributes(),
    outgoingApplication: outgoingApplication,
  };
  splunkLogger.queueEvent(LogEventName.EXTERNAL_APPLICATION_NAVIGATION, customAttributes);
}

export function updateDefaultAttributes() {
  splunkLogger.updateDefaultAttributes({ ...buildDefaultAttributes(), ...buildCustomAttributes() });
}

export function buildCustomAttributes() {
  const { deviceState } = detectDevice();
  return {
    accessedByExperience: deviceState,
  };
}

async function flushLogs() {
  await splunkLogger?.flush();
}

export function isLoggingNetworkRequestAndResponse() {
  return getAppConfigCache()?.splunkConfig.logNetworkRequestAndResponse ?? false;
}

export function logException(exceptionAttributes: ExceptionAttributes) {
  const customAttributes = buildCustomAttributes();
  splunkLogger?.queueException(exceptionAttributes, customAttributes);
  flushLogs();
}

export function logError(error: unknown, message?: string) {
  splunkLogger?.queueError(
    { error: { message: message ?? '', supportInformation: error }, severity: ErrorSeverity.Error },
    buildCustomAttributes()
  );
}

window.addEventListener(BEFORE_UNLOAD, flushLogs, false);
