import { Contact, NoProfileRenter } from 'services/booking/bookingTypes';
import { DriverData, RenterTypes } from 'components/shared/uiModels/driver/driverData';
import { DriverProfile } from 'services/renter/driverProfile';
import { TransactionalProfileResponseContent } from 'services/renter/transactionalProfile';
import { parseUrn } from 'utils/urnUtils';

export const formatName = (lastName?: string, firstName?: string): string => {
  if (lastName && firstName) {
    return `${lastName}, ${firstName}`;
  } else if (lastName && !firstName) {
    return lastName;
  } else if (!lastName && firstName) {
    return firstName;
  }
  return '';
};

/**
 * Transforms driver info from NoProfileRenter
 * */
export const transformToDriverFromNoProfile = (noProfileRenter: NoProfileRenter, contact?: Contact): DriverData => {
  return {
    type: RenterTypes.NoProfile,
    firstName: noProfileRenter.name?.given,
    lastName: noProfileRenter.name?.surname,
    age: noProfileRenter?.age,
    email: contact?.email,
    phone: {
      number: contact?.phone?.number,
      extension: contact?.phone?.extension,
    },
    contact: {
      name: contact?.name,
    },
  };
};

/**
 * Transforms driver info from Renter Driver Profile
 * */
export const transformToDriverFromDriverProfile = (driverProfile: DriverProfile): DriverData => {
  const email = driverProfile.emailAddresses?.find((email) => email.priority === 1);
  const phone = driverProfile.phoneNumbers?.find((phone) => phone.priority === 1);
  return {
    type: RenterTypes.DriverProfile,
    firstName: driverProfile.name?.givenName,
    lastName: driverProfile.name?.surname,
    age: driverProfile?.age,
    email: email?.emailAddress,
    phone: {
      type: parseUrn(phone?.phoneType),
      number: phone?.number,
      extension: phone?.extension,
      priority: phone?.priority,
    },
    contact: {
      name: formatName(driverProfile.name?.surname, driverProfile.name?.givenName),
    },
    // ToDo - https://jira.ehi.com/browse/GRES-256
    address: undefined,
  };
};

/**
 * Transforms driver info from People Transactional Profile
 * */
export const transformToDriverFromTransactionalProfile = (
  transactionalProfile: TransactionalProfileResponseContent
): DriverData => {
  const email = transactionalProfile.contactInformation?.emailAddresses?.find((email) => email.priority === 1);
  const phone = transactionalProfile.contactInformation?.phoneNumbers?.find((phone) => phone.priority === 1);
  return {
    type: RenterTypes.TransactionalProfile,
    firstName: transactionalProfile.personalInformation?.name?.givenName,
    lastName: transactionalProfile.personalInformation?.name?.surname,
    // ToDo calculate age
    // age: transactionalProfile.personalInformation?.,
    email: email?.emailAddress,
    phone: {
      type: parseUrn(phone?.type),
      number: phone?.number,
      extension: phone?.extension,
      priority: phone?.priority,
    },
    contact: {
      name: formatName(
        transactionalProfile.personalInformation?.name?.surname,
        transactionalProfile.personalInformation?.name?.givenName
      ),
    },
    // ToDo - https://jira.ehi.com/browse/GRES-256
    address: undefined,
  };
};
