import { NavigationConstants } from 'components/navigation/navigationConstants';
import { DeepLinkProps } from 'components/deeplink/deepLinkProps';
import { validate001 } from 'components/deeplink/deepLinkValidator';
import { DeeplinkIds, getDeeplinkProps } from 'components/deeplink/deepLinkUtil';
import { RouterPaths } from 'app/router/RouterPaths';

export type NoParams = undefined;

type DeepLinkFactory = {
  create: (params: URLSearchParams) => DeepLinkProps;
};

export const createDeepLink = (validate: (params: URLSearchParams) => void, pathName: string): DeepLinkFactory => {
  return {
    create: (params: URLSearchParams) => {
      try {
        validate(params);
      } catch (e) {
        return getDeeplinkProps(params, RouterPaths.Home);
      }
      return getDeeplinkProps(params, pathName);
    },
  };
};
const deepLinkFactory = (deeplinkId: string): DeepLinkFactory => {
  switch (deeplinkId) {
    case DeeplinkIds.HOME:
      return createDeepLink(validate001, RouterPaths.Home);
    case DeeplinkIds.SEARCH:
      return createDeepLink(validate001, RouterPaths.Search);
    case DeeplinkIds.RES_SUMMARY:
      return createDeepLink(validate001, RouterPaths.ResSummary);
    default:
      return createDeepLink(validate001, RouterPaths.SetLocation);
  }
};

export const deepLinkTo = (searchParams: URLSearchParams): DeepLinkProps | NoParams => {
  const deeplinkId = searchParams.get(NavigationConstants.DEEP_LINK_ID_PARAM);

  // ignore validation as navigating with in the app won't have deepLinkId.
  if (!deeplinkId) return undefined;
  const factory = deepLinkFactory(deeplinkId);

  return factory.create(searchParams);
};
