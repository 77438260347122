import { Box, Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ResSummaryDrawerContext } from 'context/ResSummaryDrawerContext';
import { useContext } from 'react';
import { ResSummaryDrawerHeader } from 'components/resSummary/ReservationSummaryDrawer.styles';
import { InlineSummaryContainer } from 'components/resSummary/InlineSummaryContainer';
import { Body1 } from '@ehi/ui';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { DriversSummary } from 'components/resSummary/driverSummary/DriversSummary';
import { WhenAndWhereSummary } from 'components/resSummary/whenAndWhere/WhenAndWhereSummary';
import { RateSourceAndBillToSummary } from 'components/resSummary/rateSourceAndBillTo/RateSourceAndBillToSummary';
import { VehicleSummary } from 'components/resSummary/vehicle/VehicleSummary';
import { AddOnsSummary } from 'components/resSummary/AddOnsSummary/AddOnsSummary';
import { PaymentSummary } from 'components/resSummary/payment/PaymentSummary';
import { useTranslations } from 'components/shared/i18n';
import useMediaQuery from '@mui/material/useMediaQuery';

export const InlineReservationSummary = (): JSX.Element => {
  const { t } = useTranslations();
  const { setOpenDrawer } = useContext(ResSummaryDrawerContext);
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');

  return (
    <InlineSummaryContainer>
      <ResSummaryDrawerHeader>
        <span>{t('reservationSummary.title')}</span>
        <IconButton aria-label='close' onClick={() => setOpenDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </ResSummaryDrawerHeader>
      <Divider />
      <Box alignItems={'center'} margin={2}>
        {isMobileOrTablet && (
          <div>
            <Body1 display={'inline'} marginRight={'10px'} bold>
              Unfullfilled
            </Body1>
            <Caption2 display={'inline'}>RES# 628675309</Caption2>
          </div>
        )}
        <DriversSummary />
        <WhenAndWhereSummary />
        <RateSourceAndBillToSummary />
        <VehicleSummary />
        <AddOnsSummary />
        <PaymentSummary />
      </Box>
    </InlineSummaryContainer>
  );
};
