import useMediaQuery from '@mui/material/useMediaQuery';
import { Drawer } from '@mui/material';
import { ReactNode, useContext } from 'react';
import { ResSummaryDrawerContext } from 'context/ResSummaryDrawerContext';
import { DrawerContainer, SummaryContent } from 'components/resSummary/ReservationSummaryDrawer.styles';

export type InlineSummaryProps = {
  children: ReactNode;
};

type SummaryDrawerProps = {
  children: ReactNode;
};

export const InlineSummaryContainer = ({ children }: InlineSummaryProps): JSX.Element => {
  return (
    <InlineSummaryWrapper>
      <DrawerContainer>{children}</DrawerContainer>
    </InlineSummaryWrapper>
  );
};

const InlineSummaryWrapper = ({ children }: SummaryDrawerProps): JSX.Element => {
  const { openDrawer, setOpenDrawer } = useContext(ResSummaryDrawerContext);
  const isMobileOrTablet = useMediaQuery('(max-width:800px)');
  return isMobileOrTablet ? (
    <Drawer style={{ zIndex: 2001 }} anchor={'left'} open={openDrawer} onClose={() => setOpenDrawer(false)}>
      {children}
    </Drawer>
  ) : (
    <>{openDrawer && <SummaryContent>{children}</SummaryContent>}</>
  );
};
