import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexiFunctionComponent';
import { Card } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { ProtectionsSection } from './peoSections/ProtectionsSection';
import { DividerWithFullWidth } from 'components/shared/ui/styles/Divider.styles';
import { EquipmentSection } from './peoSections/EquipmentSection';
import { OtherChargesSection } from './peoSections/OtherChargesSection';
import { MiscellaneousSection } from './peoSections/MiscellaneousSection';

export const AddOns: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  return (
    <Card>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <CardContent>
        <Card>
          <CardContent>
            <ProtectionsSection />
            <DividerWithFullWidth />
            <EquipmentSection />
            <DividerWithFullWidth />
            <OtherChargesSection />
            <DividerWithFullWidth />
            <MiscellaneousSection />
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};
