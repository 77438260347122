import { Body1 } from '@ehi/ui';
import { Box } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';

export const OtherChargesSection = () => {
  const { t } = useTranslations();
  return (
    <Box>
      <Body1 bold>{t('peo.otherCharges')}</Body1>
      <Caption2>{t('peo.noOptions')}</Caption2>
    </Box>
  );
};
