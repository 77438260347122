import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1 } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';

export const PaymentSummary = (): JSX.Element => {
  const { t } = useTranslations();
  return (
    <ReservationSummaryCard title={t('reservationSummary.payment')} loading={false}>
      <CardContent>
        <Grid container direction={'column'}>
          <Grid item xs>
            <Caption2>{'Payment Method'}</Caption2>
            <Body1 bold>{'Credit Card'}</Body1>
          </Grid>
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
