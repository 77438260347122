import { useCallback } from 'react';
import { useLocationService } from 'services/location/useLocationService';
import { parseUrn } from 'utils/urnUtils';
import { Error002 } from 'components/shared/errors/ErrorCodes';
import { parseBranch } from 'services/location/locationRollupUtils';
import { LocationAndArea } from 'services/location/locationRollupTypes';
import { filterBranches, isECARSOnlyBranch } from 'utils/locationUtils';
import { fetchQuery } from 'components/shared/react-query/queryUtil';
import { DOMAIN_CACHE_OPTIONS } from 'components/shared/react-query/cacheOptions';
import _ from 'lodash';
import { QUERY_KEY_LOCATION_AND_AREA } from 'services/location/locationCache';
import { fetchBranch, fetchStation } from 'services/location/locationQueries';
import { Station } from 'services/location/locationTypes';
import { EhiCounter } from '@ehi/location';

export type LocationRollUpServiceHook = {
  getLocationAndArea: (locationId: string) => Promise<LocationAndArea>;
  queryLocationAndArea: (locationId: string) => Promise<LocationAndArea>;
};

export const useLocationRollUpService = (): LocationRollUpServiceHook => {
  const { getMultipleBranchDetailsCall, getBranchHierarchyCall } = useLocationService();

  const getLocationAndArea = useCallback(
    async (locationId: string): Promise<LocationAndArea> => {
      // search for locations
      const branchDetails = await getMultipleBranchDetailsCall([locationId.toUpperCase()]);
      const branches = await Promise.all(
        branchDetails.map((branch) => {
          return fetchBranch(parseUrn(branch.urn));
        })
      );
      const filteredBranches = filterBranches(branches);
      if (filteredBranches.length < 1) {
        return Promise.reject(new Error(Error002));
      }

      // Retrieve counter info for primary station
      let station: Station | undefined;
      if (!isECARSOnlyBranch(filteredBranches[0]) && filteredBranches[0].stationId !== undefined) {
        station = await fetchStation(filteredBranches[0].stationId);
      }
      const counters = _(station?.counters)
        .uniqBy((counter) => counter.ordinal)
        .map((counter) => {
          return { description: counter.name ?? '', counterId: counter.ordinal ?? '' };
        })
        .sort((a: EhiCounter, b: EhiCounter) => {
          return a?.counterId.localeCompare(b?.counterId) || 0;
        })
        .valueOf();

      const location = parseBranch(filteredBranches[0], counters);

      // Retrieve branch hierarchy to get area details
      const { area } = await getBranchHierarchyCall(location.id);
      return {
        location,
        area: {
          id: area.id,
          urn: area.urn,
        },
        defaultCounter: counters.length > 0 ? counters[0] : undefined,
      };
    },
    [getBranchHierarchyCall, getMultipleBranchDetailsCall]
  );

  const queryLocationAndArea = async (locationId: string) => {
    return fetchQuery({
      queryKey: [QUERY_KEY_LOCATION_AND_AREA, locationId?.toUpperCase()],
      queryFn: () => getLocationAndArea(locationId),
      ...DOMAIN_CACHE_OPTIONS,
    });
  };

  return {
    getLocationAndArea,
    queryLocationAndArea,
  };
};
