// Location API service objects

/**
 * @format urn
 * @pattern ^urn:com.ehi:[a-zA-Z]+:location:branch:[0-9]{7}
 * @example urn:com.ehi:prd:location:branch:1000001
 */
export type BranchUrn = string;

/**
 * @deprecated This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. Colloquially known as the Peoplesoft ID. This field is being deprecated in favor of using the URN for a branch.
 * @pattern ^[0-9]{7}$
 * @example 1000001
 */
export type BranchPeopleSoftId = string;

/**
 * @deprecated This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. Deprecated in favor of using Operational ID.
 * @example 0101
 */
export type BranchLegacyId = string;

/**
 * Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists.
 * @example 0101
 */
export type BranchOperationalId = string;

/**
 * Station ID for the branch used for Odyssey reservation system branches. If the Reservation system is ECARS and the branch is not a Spread, the field will default to 'E1 followed by Legacy Group Branch ID'. If the branch is a Spread, the default value is N/A.
 * @example E10101
 */
export type StationId = string;

/**
 * Global Distribution System ID associated with the branch. This field is not available for Spread Branches. This ID is used to set up a branch in the GDS system, a required process for branches looking to take reservations and fulfill rentals in the GDS system.
 * @pattern ^[A-Z]{4}[0-9]{2}$
 * @example STLW01
 */
export type GdsId = string;

/**
 * Legacy Description
 * @example LADUE RENTAL
 */
export type LegacyDescription = string;

/**
 * The branch phase associated with the branch
 * @example OPEN
 */
export enum BranchPhase {
  CREATE = 'CREATE',
  OPEN = 'OPEN',
  CLOSE = 'CLOSE',
  DELETE = 'DELETE',
}

/**
 * The type associated with the branch
 * @example DAILY RENTAL
 */
export enum BranchType {
  CALLBACKCENTERADMINISTRATIVE = 'CALLBACK CENTER ADMINISTRATIVE',
  CARSALESADMINISTRATIVE = 'CAR SALES ADMINISTRATIVE',
  CARSALESREGULAR = 'CAR SALES-REGULAR',
  CARSALESSPECIALSALES = 'CAR SALES-SPECIAL SALES',
  CORPORATE = 'CORPORATE',
  CORPORATERENTAL = 'CORPORATE - RENTAL',
  DAILYRENTAL = 'DAILY RENTAL',
  DAILYRENTALADMINISTRATIVE = 'DAILY RENTAL ADMINISTRATIVE',
  FLEETSERVICES = 'FLEET SERVICES',
  FLEETSERVICESADMINISTRATIVE = 'FLEET SERVICES ADMINISTRATIVE',
  FLEXERENT = 'FLEX-E-RENT',
  GHOSTBRANCH = 'GHOST BRANCH',
  GROUPADMINISTRATIVE = 'GROUP ADMINISTRATIVE',
  ODYSSEYNONREVENUE = 'ODYSSEY NON-REVENUE',
  OTHERADMINISTRATIVE = 'OTHER ADMINISTRATIVE',
  REGIONALADMINISTRATIVE = 'REGIONAL ADMINISTRATIVE',
  REMARKETINGADMINISTRATIVE = 'REMARKETING ADMINISTRATIVE',
  RIDESHARE = 'RIDE SHARE',
  RIDESHAREADMINISTRATIVE = 'RIDE SHARE ADMINISTRATIVE',
  SERVICE = 'SERVICE',
  SERVICEADMINISTRATIVE = 'SERVICE ADMINISTRATIVE',
  SPREADADMINDAILYRENTAL = 'SPREAD ADMIN-DAILY RENTAL',
  SPREADADMINFLEETSERVICES = 'SPREAD ADMIN-FLEET SERVICES',
  SPREADADMINOTHER = 'SPREAD ADMIN-OTHER',
  SPREADADMINREFERRAL = 'SPREAD ADMIN-REFERRAL',
  TRAININGADMINISTRATIVE = 'TRAINING ADMINISTRATIVE',
  TRUCKRENTAL = 'TRUCK RENTAL',
  TRUCKRENTALADMINISTRATIVE = 'TRUCK RENTAL ADMINISTRATIVE',
}

/**
 * The brand associated with the branch
 * @example ENTERPRISE
 */
export enum Brand {
  ALAMO = 'ALAMO',
  ATESA = 'ATESA',
  CITER = 'CITER',
  ENTERPRISE = 'ENTERPRISE',
  NATIONAL = 'NATIONAL',
}

/**
 * A string denoting the timezone in which the branch resides.
 * @example America/New_York
 */
export type TimeZone = string;

export type GeoCode = {
  /**
   * @min -90
   * @max 90
   * @example 38.6473
   */
  latitude: number;

  /**
   * @min -180
   * @max 180
   * @example -90.336
   */
  longitude: number;
};

export type GeoLocation = {
  /**
   * @min -90
   * @max 90
   * @example 38.6473
   */
  latitude: number;

  /**
   * @min -180
   * @max 180
   * @example -90.336
   */
  longitude: number;
};

export type AddressSearch = {
  /**
   * First line of the address
   * @example 8844 LADUE ROAD
   */
  line1?: string;

  /**
   * Second line of the address
   * @example SUITE 200
   */
  line2?: string;

  /**
   * Name of the city
   * @example LADUE
   */
  city?: string;

  /**
   * Name of the county
   * @example ST. LOUIS
   */
  county?: string;

  /**
   * Name of the country subdivision
   * @example Missouri
   */
  countrySubdivision?: string;

  /**
   * The zip code or postal code appropriate for the country of this address
   * @example 63124
   */
  postalCode?: string;

  /**
   * The ISO3 country code of this address
   * @example USA
   */
  countryCode?: string;
};

export type Addresses = Array<Address>;

export type Address = AddressSearch & {
  type?: 'MAILING' | 'PHYSICAL' | 'REGISTRATION' | 'SHIPPING';
  default?: boolean;
};

/**
 * Branch phone number
 */
export type Phone = {
  /**
   * Type of phone number
   * @example AFTER HOURS
   */
  type: 'AFTER HOURS' | 'FAX' | 'OFFICE' | 'VAA DEPT FAX';

  /**
   * Default phone number for branch
   * @example true
   */
  default: boolean;

  /**
   * Branch phone number
   * @example 3056480665
   */
  number: string;
};

/**
 * Primary or Secondary language for the branch
 */
export type Language = {
  /**
   * 2 character string representation of the language
   * @example en
   */
  code: string;

  /**
   * Language description
   * @example English
   */
  description: string;
};

/**
 * The locale in ISO 639-3 for language and ISO 3166-1 alpha-3 for country code.
 * @pattern [a-z]{3}-[A-Z]{3}
 * @example eng-USA
 */
export type Locale = string;

export type Names = {
  /**
   * The localized display name for this Branch
   * @example South Bend Airport
   */
  display?: string;

  /** The locale in ISO 639-3 for language and ISO 3166-1 alpha-3 for country code. */
  displayNameLocale?: Locale;

  /**
   * The accounting name for this Branch
   * @example RIDESHAR
   */
  accounting?: string;

  /**
   * The advertising name for this Branch
   * @example RIDESHARE WASHO
   */
  advertising?: string;

  /**
   * The mailing name for this Branch
   * @example RIDESHARE DOUGLAS COUNTY
   */
  mailing?: string;

  /**
   * The print name for this Branch
   * @example ENTERPRISE RENT A CAR CANADA COMPANY GST 889365821
   */
  print?: string;

  /**
   * The shipping name for this Branch
   * @example RIDESHARE DOUGLAS COUNTY
   */
  shipping?: string;
};

/**
 * Indicates whether or not the branch is the primary branch for its station among branches that share its station ID.
 * @example true
 */
export type PrimaryStation = boolean;

export type LicenseeConfiguration = {
  /**
   * Defines FRB Partner Code field for franchisees
   * @pattern ^[A-Z0-9]{1,3}$
   * @example OTO
   */
  partnerCode?: string;

  /**
   * Defines TSD Instance ID field for franchisees
   * @pattern ^[A-Z0-9]{1,5}$
   * @example 49005
   */
  tsdInstanceId?: string;

  /**
   * The VG PSoft Dept ID field was originally added as part of the Vanguard integration.  It was associated with financials when the VG PeopleSoft ledger was still maintained.  In Odyssey, it was primarily used to the Maintain Company Details screens to create financials and as the billing department number.
   * @pattern ^[A-Z0-9]{3,7}$
   * @example 7700
   */
  vanguardPeopleSoftId?: string;
};

/**
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z]+:location:airport:[A-Z]{3}
 * @example urn:com.ehi:prd:location:airport:MCO
 */
export type AirportUrn = string;

/**
 * 3-Character airport code for the airport that is associated with the branch.
 * @pattern ^[A-Z]{3}$
 * @example STL
 */
export type AirportCode = string;

export type AirportBranch = {
  airport?: AirportUrn;

  /** 3-Character airport code for the airport that is associated with the branch. */
  code?: AirportCode;

  /**
   * Indicates the proximity of a branch to the airport it services. A branch with a proximity of `TERMINAL` would reside in or be attached to an airport terminal, while a branch with a proximity of `OFFSITE` might service an airport but reside in a location that is within the geographical bounds of the airport.
   * @example TERMINAL
   */
  proximity?: 'TERMINAL' | 'OFFSITE' | 'ONSITE' | 'CONSOLIDATED';
};

/**
 * @example DAILY_RENTAL
 */
export enum BusinessType {
  ADMINISTRATION_OFFICE = 'ADMINISTRATION_OFFICE',
  ALAMO_DAILY_RENTAL = 'ALAMO_DAILY_RENTAL',
  ALAMO_LEASING = 'ALAMO_LEASING',
  ALAMO_ONEWAY_ANYWHERE_PICKUP = 'ALAMO_ONEWAY_ANYWHERE_PICKUP',
  ALAMO_ONEWAY_ANYWHERE_RETURN = 'ALAMO_ONEWAY_ANYWHERE_RETURN',
  ALAMO_ONEWAY_LOCAL_WITHIN_GP = 'ALAMO_ONEWAY_LOCAL_WITHIN_GP',
  ALAMO_SERVICE_LOCATION = 'ALAMO_SERVICE_LOCATION',
  ARMS_CLAIMS_CONNECTION_LOC = 'ARMS_CLAIMS_CONNECTION_LOC',
  ARMS_FLEET_SERVICES = 'ARMS_FLEET_SERVICES',
  ARMS_TRUCK_RENTAL = 'ARMS_TRUCK_RENTAL',
  CAR_SALES_ADMINISTRATIVE = 'CAR_SALES_ADMINISTRATIVE',
  CAR_SALES_CONSUMER_RETAIL = 'CAR_SALES_CONSUMER_RETAIL',
  CAR_SALES_FULL_RETAIL = 'CAR_SALES_FULL_RETAIL',
  CAR_SALES_INCOMING = 'CAR_SALES_INCOMING',
  CAR_SALES_LIMITED_RETAIL = 'CAR_SALES_LIMITED_RETAIL',
  CAR_SALES_RECONDITIONING = 'CAR_SALES_RECONDITIONING',
  CAR_SALES_REMARKETING = 'CAR_SALES_REMARKETING',
  CAR_SHARE = 'CAR_SHARE',
  CORPORATE_IN_HOUSE_LOCATION = 'CORPORATE_IN_HOUSE_LOCATION',
  CRAWFORD_GROUP_DAILY_RENTAL = 'CRAWFORD_GROUP_DAILY_RENTAL',
  CRAWFORD_GROUP_LEASING = 'CRAWFORD_GROUP_LEASING',
  CRAWFORD_GROUP_SERVICE_LOC = 'CRAWFORD_GROUP_SERVICE_LOC',
  DAILY_RENTAL = 'DAILY_RENTAL',
  DAILY_RENTAL_AUTOMATED_INDICATOR = 'DAILY_RENTAL_AUTOMATED_INDICATOR',
  ENTERPRISE_DAILY_RENTAL = 'ENTERPRISE_DAILY_RENTAL',
  ENTERPRISE_LEASING_LOCATIONS = 'ENTERPRISE_LEASING_LOCATIONS',
  ENTERPRISE_SERVICE_LOCATION = 'ENTERPRISE_SERVICE_LOCATION',
  EXCLUDED_FROM_ROADSIDE_EXCHANGE = 'EXCLUDED_FROM_ROADSIDE_EXCHANGE',
  EXOTIC_CAR_LOCATION = 'EXOTIC_CAR_LOCATION',
  FBO_ONLY_NO_RETAIL = 'FBO_ONLY_NO_RETAIL',
  FIXED_BASED_OPERATIONS = 'FIXED_BASED_OPERATIONS',
  FLEET_TRUCK_RENTAL = 'FLEET_TRUCK_RENTAL',
  HYBRID_DAILY_RENTAL_LOCATIONS = 'HYBRID_DAILY_RENTAL_LOCATIONS',
  INSURANCE_REPLACEMENT = 'INSURANCE_REPLACEMENT',
  INTERNET_CAR_SALES = 'INTERNET_CAR_SALES',
  INTERNET_LEASING_LOCATIONS = 'INTERNET_LEASING_LOCATIONS',
  INTERNET_RESERVATION = 'INTERNET_RESERVATION',
  INTERNET_TRUCK_RENTAL_RESERVATIONS = 'INTERNET_TRUCK_RENTAL_RESERVATIONS',
  INT_ONEWAY_ANYWHERE_PICKUP_LOC = 'INT_ONEWAY_ANYWHERE_PICKUP_LOC',
  INT_ONEWAY_ANYWHERE_RETURN_LOC = 'INT_ONEWAY_ANYWHERE_RETURN_LOC',
  INT_ONEWAY_LOCAL_WITHIN_GP = 'INT_ONEWAY_LOCAL_WITHIN_GP',
  LEASING_LOCATIONS = 'LEASING_LOCATIONS',
  LIMIT_TO_ECARS_RESERVATION = 'LIMIT_TO_ECARS_RESERVATION',
  LOYALTY_EARN = 'LOYALTY_EARN',
  LOYALTY_REDEMPTION = 'LOYALTY_REDEMPTION',
  MONTH_OR_MORE_RENTAL_LOCATION = 'MONTH_OR_MORE_RENTAL_LOCATION',
  MOTORCYCLE_LOCATION = 'MOTORCYCLE_LOCATION',
  MULTI_CITY_LOCATION = 'MULTI_CITY_LOCATION',
  MULTIPLE_TERMINALS = 'MULTIPLE_TERMINALS',
  NATIONAL_DAILY_RENTAL = 'NATIONAL_DAILY_RENTAL',
  NATIONAL_LEASING = 'NATIONAL_LEASING',
  NATIONAL_SERVICE_LOCATION = 'NATIONAL_SERVICE_LOCATION',
  NATL_ONEWAY_ANYWHERE_PICKUP = 'NATL_ONEWAY_ANYWHERE_PICKUP',
  NATL_ONEWAY_ANYWHERE_RETURN = 'NATL_ONEWAY_ANYWHERE_RETURN',
  NATL_ONEWAY_LOCAL_WITHIN_GRP = 'NATL_ONEWAY_LOCAL_WITHIN_GRP',
  NATRES_RESERVATION_LOCATION = 'NATRES_RESERVATION_LOCATION',
  NR_ONEWAY_ANYWHERE_PICKUP_LOC = 'NR_ONEWAY_ANYWHERE_PICKUP_LOC',
  NR_ONEWAY_ANYWHERE_RETURN_LOC = 'NR_ONEWAY_ANYWHERE_RETURN_LOC',
  NR_ONEWAY_LOCAL_WITHIN_GROUP = 'NR_ONEWAY_LOCAL_WITHIN_GROUP',
  ONEWAY_ANYWHERE_PICKUP_LOC = 'ONEWAY_ANYWHERE_PICKUP_LOC',
  ONEWAY_ANYWHERE_RETURN_LOC = 'ONEWAY_ANYWHERE_RETURN_LOC',
  ONEWAY_LOCAL_WITHIN_GROUP = 'ONEWAY_LOCAL_WITHIN_GROUP',
  OVERDUE_RENTAL_EMAIL_CAMPAIGN = 'OVERDUE_RENTAL_EMAIL_CAMPAIGN',
  PICK_YOU_UP = 'PICK_YOU_UP',
  POINT_OF_INTEREST_LOCATION = 'POINT_OF_INTEREST_LOCATION',
  PORT_OF_CALL = 'PORT_OF_CALL',
  RENTER_VERIFICATION = 'RENTER_VERIFICATION',
  RESTRICTED_ACCESS = 'RESTRICTED_ACCESS',
  RETAIL_TRUCK_RENTAL_LOCATION = 'RETAIL_TRUCK_RENTAL_LOCATION',
  RIDE_SHARE = 'RIDE_SHARE',
  SERVICE_LOCATION = 'SERVICE_LOCATION',
  SISTER_BRANCH_LOCTIONS_ONLY = 'SISTER_BRANCH_LOCTIONS_ONLY',
  SUBSCRIPTION_SERVICE = 'SUBSCRIPTION_SERVICE',
  TNC_ONLY = 'TNC_ONLY',
  TRAIN_STATION = 'TRAIN_STATION',
  TRUCK_RENTAL = 'TRUCK_RENTAL',
  TRUCK_RENTAL_AUTOMATED_INDICATOR = 'TRUCK_RENTAL_AUTOMATED_INDICATOR',
  USED_CAR_LOCATION = 'USED_CAR_LOCATION',
}

export type BusinessTypes = Array<BusinessType>;

/**
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:businessIndicator:[A-Z_]+$
 * @example urn:com.ehi:xqa:reference:location:businessIndicator:ACCEPTS_E_COM_ONE_WAY
 */
export type BusinessIndicator = string;

export type Indicators = Array<BusinessIndicator>;

export type OdysseyConfiguration = {
  /** Contract Fuel Override Indicator indicates if a station overrides fuel price from a contract. */
  allowFuelOverride?: boolean;

  /** The reservation search indicator specifies whether this location should be listed on the reservation station search list.  This flag will not prevent reservations from being made but will prevent the station from appearing on the search list. */
  allowReservationSearch?: boolean;

  /**
   * Debit card policy code is a number that uniquely identifies the debit card policy types.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:debitCardPolicy:[A-Z]{1}[_A-Z0-9]+$
   * @example urn:com.ehi:xqa:reference:location:debitCardPolicy:MANUAL_RENTAL_WITH_OVERRIDE
   */
  debitCardPolicy?: string;

  /**
   * The document print language code uniquely identifies the language in which documents generated at this branch will be printed.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:documentPrintingLanguage:[A-Z]{2}$
   * @example urn:com.ehi:xqa:reference:location:documentPrintingLanguage:FR
   */
  documentPrintingLanguage?: string;

  /**
   * The station category type code is an unique code that specifies whether employees are corporate employees (EHI), franchisee employees, or in transition from being a francinsee employee to a corporate employee.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:employeeAffiliation:[A-Z]{1}[_A-Z0-9]+$
   * @example urn:com.ehi:xqa:reference:location:employeeAffiliation:AGENCY
   */
  employeeAffiliation?: string;

  /**
   * Fee Override Type Code indicates if the driver's zip code needs to be checked and captured as part of the rental process.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:feeOverride:[A-Z]{1}[_A-Z0-9]+$
   * @example urn:com.ehi:xqa:reference:location:feeOverride:POSTAL_CODE_CHECK
   */
  feeOverride?: string;

  /**
   * The handheld printer type code is a unique code that identifies the make and model of the handheld printer that is in use by this branch.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:handheldPrinterType:[A-Z]{1}[_A-Z0-9]+$
   * @example urn:com.ehi:xqa:reference:location:handheldPrinterType:MF3
   */
  handheldPrinterType?: string;

  /**
   * The live Odyssey station indicator designates if this branch is automated and uses the Odyssey system or not.
   * @example true
   */
  isLiveOdysseyStation?: boolean;

  /**
   * Indicates if a station acts as the primary or secondary insurance provider.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:insuranceProviderType:[A-Z]{1}[_A-Z0-9]+$
   * @example urn:com.ehi:xqa:reference:location:insuranceProviderType:PRIMARY
   */
  insuranceProviderType?: string;
};

/**
 * Contains information describing operational profile of the location, such as inside a hotel, a remarketing department inside a building, or the inside of a body shop.
 */
export type OperationalLocationType = {
  /**
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:operationalLocationType:[A-Z_]+$
   * @example urn:com.ehi:xqa:reference:location:operationalLocationType:MILITARY_BASE
   */
  type: string;

  /**
   * Additional information about the operational profile, such as the name of a dealership.
   * @example space in body shop office
   */
  description?: string;
};

export type FleetManagement = {
  /**
   * Inventory Control Type Code indicates whether or not the station uses a virtual inventory system or whether inventory is controlled out of another station.
   * @example true
   */
  isInventoryControlled?: boolean;
  sharedInventoryBranch?: BranchUrn;
};

/**
 * The computer system in which fulfillments are tracked and processed.
 * @example ODYSSEY
 */
export enum FulfillmentBookingSystems {
  ECARS = 'ECARS',
  ODYSSEY = 'ODYSSEY',
}

/**
 * The One-Way Rental configuration for a Branch which indicates the scope of the Branch's One-Way Rental capabilities.
 * @example LOCAL
 */
export enum OneWayRental {
  FULL = 'FULL',
  LOCAL = 'LOCAL',
  NONE = 'NONE',
}

/**
 * Indicates the amount of advanced notice in hours required when booking a reservation.
 * @format float
 * @min 0
 * @max 999.99
 * @pattern ^[0-9]{1,3}\.[0-9]{1,2}$
 * @example 4.25
 */
export type ReservationLeadTime = number;

/**
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:vehicle:vehicleClass:[CDEFGHIJLMNOPRSUWX]{1}[BCDEFGHIJKLMNOPQRSTVWXYZ]{1}[ABCDMN]{1}[ABCDEFHILMNQRSUVXZ]{1}$
 * @example urn:com.ehi:xqa:reference:vehicle:vehicleClass:ICAR
 */
export type VehicleClass = string;

export type RentalConfiguration = {
  /**
   * @format currency
   * @min 0
   * @max 999999999999.999
   * @example 10000
   */
  afterHoursFee?: number;

  /**
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:businessAccount:account:[a-zA-Z0-9]{3,8}$
   * @example urn:com.ehi:xqa:businessAccount:account:XJ12345
   */
  businessAccount?: string;

  /**
   * Auto Station Indicator indicates whether the station has the ability to receive reservations electronically.
   * @example true
   */
  isAutoStation?: boolean;

  /** The computer system in which fulfillments are tracked and processed. */
  bookingSystem?: FulfillmentBookingSystems;

  /**
   * Default Walk In Indicator indicates if the station allows walk-ins.
   * @example true
   */
  allowWalkin?: boolean;

  /** @example true */
  displayWebsiteMap?: boolean;

  /** @example true */
  displayTruckLink?: boolean;

  /**
   * The determines whether an Odyssey branch can accept coupons at Emerald Aisle or not.
   * @example true
   */
  acceptsEmeraldAisleCoupons?: boolean;

  /** The computer system in which fulfillments are tracked and processed. */
  fulfillmentSystem?: FulfillmentBookingSystems;

  /** The One-Way Rental configuration for a Branch which indicates the scope of the Branch's One-Way Rental capabilities. */
  oneWayRental?: OneWayRental;

  /** Indicates the amount of advanced notice in hours required when booking a reservation. */
  reservationLeadTime?: ReservationLeadTime;
  availableVehicleClasses?: AvaliableVehicleClasses;
};

export type AvaliableVehicleClasses = Array<AvaliableVehicleClass>;

export type AvaliableVehicleClass = {
  vehicleClass?: VehicleClass;
  availabilityBranch?: BranchUrn;
  availabilityPhoneNumber?: { number?: string; dialingCode?: string };
};

export type AvailabilityPhoneNumber = { number?: string; dialingCode?: string };

/**
 * The type of travel available at this Branch
 */
export enum TravelType {
  FLIGHT = 'FLIGHT',
  TRAIN = 'TRAIN',
  SHIP = 'SHIP',
}

/**
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:usageType:[A-Z_]+$
 * @example urn:com.ehi:xqa:reference:location:usageType:FULL
 */
export type UsageType = string;

export type OperationalModel = {
  /**
   * The activity type code is unique identifier for each type of activity that could be performed by an Odyssey branch.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:activityType:[A-Z]{1}[_A-Z0-9]+$
   * @example urn:com.ehi:xqa:reference:location:activityType:INTERNATIONAL_RESERVATION_OFFICE
   */
  activityType?: string;

  /**
   * Indicated the degree to which a branch supports digital printing.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:digitalPrintingSupport:[_A-Z0-9]+$
   * @example urn:com.ehi:xqa:reference:location:digitalPrintingSupport:LASER_NOT_USING_MESSAGING_FRAMEWORK
   */
  digitalPrintingSupport?: string;
  fleetManagement?: FleetManagement;

  /** Determines if this branch operates as a Contact Center */
  isContactCenter?: boolean;

  /**
   * The manual station communication method code is unique code that describes the method by which a manual (non-internet) branch will receive reservations.
   * @format urns
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:manualCommunicationMethod:[A-Z]{1}[_A-Z0-9]+$
   * @example urn:com.ehi:xqa:reference:location:manualCommunicationMethod:PHONE
   */
  manualCommunicationMethod?: string;
  rentalConfiguration?: RentalConfiguration;

  /**
   * The shuttle service type is a unique code which indicates how shuttle service is provided to and from the branch.
   * @format urn
   * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:shuttleInformation:[A-Z]{1}[_A-Z0-9]+$
   * @example urn:com.ehi:xqa:reference:location:shuttleInformation:NO_SHUTTLE_NON_PUBLIC_CORPORATE_CONTRACT_ONLY
   */
  shuttleInformation?: string;

  /** The type of travel available at this Branch */
  travelType?: TravelType;
  primaryUsageType?: UsageType;
};

/**
 * @example PHYSICAL
 */
export enum AddressType {
  MAILING = 'MAILING',
  PHYSICAL = 'PHYSICAL',
  REGISTRATION = 'REGISTRATION',
  SHIPPING = 'SHIPPING',
}

export type AddressesV2 = Array<AddressV2>;

export type AddressV2 = {
  type?: AddressType;
  default?: boolean;

  /**
   * First line of the address
   * @example 8844 LADUE ROAD
   */
  line1: string;

  /**
   * Second line of the address
   * @example SUITE 200
   */
  line2?: string;

  /**
   * Name of the city
   * @example LADUE
   */
  city: string;

  /**
   * Name of the county
   * @example ST. LOUIS
   */
  county?: string;
  countrySubdivision?: { abbreviation?: string; name?: string };

  /** A postal code for an address. */
  postalCode?: { code?: string; extension?: string };
  country?: { code?: string };
};

/**
 * Types of phone/fax numbers.
 */
export enum PhoneType {
  AFTER_HOURS = 'AFTER_HOURS',
  FAX = 'FAX',
  OFFICE = 'OFFICE',
  VAA_DEPT_FAX = 'VAA_DEPT_FAX',
}

export type PhoneV2 = {
  default?: boolean;

  /** Types of phone/fax numbers. */
  type?: PhoneType;

  /**
   * @pattern ^[0-9]+$
   * @example 14155552671
   */
  number?: string;

  /**
   * @pattern ^[0-9]+$
   * @example 033
   */
  dialingCode?: string;
  extension?: string;
};

export type PhonesV2 = Array<PhoneV2>;

/**
 * Indicates what line of business the regional administrative office supports. This field is only present for branches with a type of REGIONAL ADMINISTRATIVE
 */
export enum RegionalAdminSupport {
  DAILY_RENTAL = 'DAILY_RENTAL',
  TRUCK_RENTAL = 'TRUCK_RENTAL',
  RIDE_SHARE = 'RIDE_SHARE',
  FLEET_SERVICES = 'FLEET_SERVICES',
  REFERRAL = 'REFERRAL',
  SERVICE = 'SERVICE',
}

/**
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z]+:reference:location:deliveryMethod:[A-Z_]+$
 * @example urn:com.ehi:xqa:reference:location:deliveryMethod:EMERALD_COUNTER
 */
export type MethodOfDelivery = string;

export type MethodsOfDelivery = Array<MethodOfDelivery>;

/**
 * @format urn
 * @pattern ^urn:com\.ehi:[a-zA-Z0-9]+:reference:location:vehicleDeliveryCollectionMethod:[A-Z]{1}[_A-Z0-9]+$
 * @example urn:com.ehi:xqa:reference:location:vehicleDeliveryCollectionMethod:RESTRICTED_BY_ACCOUNT
 */
export type VehicleDeliveryCollectionMethod = string;

export type ServiceLevel = {
  deliveryMethods?: MethodsOfDelivery;
  vehicleDeliveryMethod?: VehicleDeliveryCollectionMethod;
  vehicleCollectionMethod?: VehicleDeliveryCollectionMethod;
};

/**
 * Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^[0-9]{7}$
 * @example 1000001
 */
export type BranchId = string;

/**
 * The ISO3 country code
 * @pattern ^[A-Z]{3}$
 * @example USA
 */
export type CountryCode = string;

/**
 * The type associated with the branch
 * @example DAILY_RENTAL
 */
export enum BranchTypeSearch {
  CALLBACK_CENTER_ADMINISTRATIVE = 'CALLBACK_CENTER_ADMINISTRATIVE',
  CAR_SALES_ADMINISTRATIVE = 'CAR_SALES_ADMINISTRATIVE',
  CAR_SALES_REGULAR = 'CAR_SALES_REGULAR',
  CAR_SALES_SPECIAL_SALES = 'CAR_SALES_SPECIAL_SALES',
  CORPORATE = 'CORPORATE',
  CORPORATE_RENTAL = 'CORPORATE_RENTAL',
  DAILY_RENTAL = 'DAILY_RENTAL',
  DAILY_RENTAL_ADMINISTRATIVE = 'DAILY_RENTAL_ADMINISTRATIVE',
  FLEET_SERVICES = 'FLEET_SERVICES',
  FLEET_SERVICES_ADMINISTRATIVE = 'FLEET_SERVICES_ADMINISTRATIVE',
  FLEX_E_RENT = 'FLEX_E_RENT',
  GHOST_BRANCH = 'GHOST_BRANCH',
  GROUP_ADMINISTRATIVE = 'GROUP_ADMINISTRATIVE',
  ODYSSEY_NON_REVENUE = 'ODYSSEY_NON_REVENUE',
  OTHER_ADMINISTRATIVE = 'OTHER_ADMINISTRATIVE',
  REGIONAL_ADMINISTRATIVE = 'REGIONAL_ADMINISTRATIVE',
  REMARKETING_ADMINISTRATIVE = 'REMARKETING_ADMINISTRATIVE',
  RIDE_SHARE = 'RIDE_SHARE',
  RIDE_SHARE_ADMINISTRATIVE = 'RIDE_SHARE_ADMINISTRATIVE',
  SERVICE = 'SERVICE',
  SERVICE_ADMINISTRATIVE = 'SERVICE_ADMINISTRATIVE',
  SPREADADMINDAILYRENTAL = 'SPREAD_ADMIN_DAILY RENTAL',
  SPREADADMINFLEETSERVICES = 'SPREAD_ADMIN_FLEET SERVICES',
  SPREAD_ADMIN_OTHER = 'SPREAD_ADMIN_OTHER',
  SPREAD_ADMIN_REFERRAL = 'SPREAD_ADMIN_REFERRAL',
  TRAINING_ADMINISTRATIVE = 'TRAINING_ADMINISTRATIVE',
  TRUCK_RENTAL = 'TRUCK_RENTAL',
  TRUCK_RENTAL_ADMINISTRATIVE = 'TRUCK_RENTAL_ADMINISTRATIVE',
}

export type Branches = Array<Branch>;

/**
 * An object representing a branch.
 */
export type Branch = {
  urn: BranchUrn;

  /** Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  id: BranchId;

  /** @deprecated This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. Colloquially known as the Peoplesoft ID. This field is being deprecated in favor of using the URN for a branch. */
  peoplesoftId: BranchPeopleSoftId;

  /** @deprecated This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. Deprecated in favor of using Operational ID. */
  legacyId: BranchLegacyId;

  /** Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  operationalId: BranchOperationalId;

  /** Legacy Description */
  legacyDescription: LegacyDescription;

  /** The branch phase associated with the branch */
  branchPhase?: BranchPhase;

  /** The ISO3 country code */
  countryCode?: CountryCode;

  /** The type associated with the branch */
  type?: BranchTypeSearch;

  /** Station ID for the branch used for Odyssey reservation system branches. If the Reservation system is ECARS and the branch is not a Spread, the field will default to 'E1 followed by Legacy Group Branch ID'. If the branch is a Spread, the default value is N/A. */
  stationId?: StationId;

  /** Indicates whether or not the branch is the primary branch for its station among branches that share its station ID. */
  primaryStation?: PrimaryStation;
};

/**
 * @format urn
 * @pattern ^urn:com.ehi:[a-zA-Z]+:location:area:AR_[A-Z]{3}_[0-9]{3}
 * @example urn:com.ehi:prd:location:area:AR_IND_018
 */
export type AreaUrn = string;

/**
 * Colloquially known as the Peoplesoft ID, this ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^AR_[A-Z]{3}_[0-9]{3}$
 * @example AR_STL_018
 */
export type AreaId = string;

/**
 * Colloquially known as the Peoplesoft ID, this ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^AR_[A-Z]{3}_[0-9]{3}$
 * @example AR_STL_018
 */
export type AreaPeoplesoftId = string;

/**
 * An ID used by the business to identify an Area.
 * @example 01S
 */
export type AreaLegacyId = string;

/**
 * Colloquially known as the legacy ID, an ID used by the business to identify an Area.
 * @example 01S
 */
export type AreaOperationalId = string;

/**
 * An object representing an Area in the EHI hierarchy.
 */
export type AreaNode = {
  urn: AreaUrn;

  /** Colloquially known as the Peoplesoft ID, this ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  id: AreaId;

  /** Colloquially known as the Peoplesoft ID, this ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  peoplesoftId: AreaPeoplesoftId;

  /** An ID used by the business to identify an Area. */
  legacyId: AreaLegacyId;

  /** Colloquially known as the legacy ID, an ID used by the business to identify an Area. */
  operationalId: AreaOperationalId;

  /** Legacy Description */
  legacyDescription: LegacyDescription;
};

/**
 * @format urn
 * @pattern ^urn:com.ehi:[a-zA-Z]+:location:city:CTY_[A-Z]{3}_[A-Z0-9]{2}
 * @example urn:com.ehi:prd:location:city:CTY_NYC_AA
 */
export type CityUrn = string;

/**
 * Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^CTY_[A-Z]{3}_[A-Z0-9]{2}$
 * @example CTY_NYC_AA
 */
export type CityId = string;

/**
 * This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^CTY_[A-Z]{3}_[A-Z0-9]{2}$
 * @example CTY_NYC_AA
 */
export type CityPeoplesoftId = string;

/**
 * This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists.
 * @pattern ^[A-Z0-9]{4}$
 * @example 24AA
 */
export type CityLegacyId = string;

/**
 * Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists.
 * @pattern ^[A-Z0-9]{4}$
 * @example 24AA
 */
export type CityOperationalId = string;

/**
 * An object representing a Sub-Region in the EHI hierarchy.
 */
export type CityNode = {
  urn: CityUrn;

  /** Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  id: CityId;

  /** This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  peoplesoftId: CityPeoplesoftId;

  /** This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  legacyId?: CityLegacyId;

  /** Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  operationalId?: CityOperationalId;

  /** Legacy Description */
  legacyDescription: LegacyDescription;
};

/**
 * @format urn
 * @pattern ^urn:com.ehi:[a-zA-Z0-9]+:location:subregion:SBR_[A-Z]{3}_[A-Z0-9]{2}
 * @example urn:com.ehi:prd:location:subregion:SBR_NYC_AA
 */
export type SubRegionUrn = string;

/**
 * Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^SBR_[A-Z]{3}_[A-Z0-9]{2}$
 * @example SBR_NYC_AA
 */
export type SubRegionId = string;

/**
 * This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^SBR_[A-Z]{3}_[A-Z0-9]{2}$
 * @example SBR_NYC_AA
 */
export type SubRegionPeoplesoftId = string;

/**
 * This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists.
 * @pattern ^[A-Z0-9]{5}$
 * @example 24AAA
 */
export type SubRegionLegacyId = string;

/**
 * Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists.
 * @pattern ^[A-Z0-9]{5}$
 * @example 24AAA
 */
export type SubRegionOperationalId = string;

/**
 * An object representing a Sub-Region in the EHI hierarchy.
 */
export type SubRegionNode = {
  urn: SubRegionUrn;

  /** Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  id: SubRegionId;

  /** This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  peoplesoftId: SubRegionPeoplesoftId;

  /** This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  legacyId?: SubRegionLegacyId;

  /** Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  operationalId?: SubRegionOperationalId;

  /** Legacy Description */
  legacyDescription: LegacyDescription;
};

/**
 * @format urn
 * @pattern ^urn:com.ehi:[a-zA-Z0-9]+:location:region:RGN_[A-Z]{3}_[A-Z0-9]{2}
 * @example urn:com.ehi:prd:location:region:RGN_NYC_99
 */
export type RegionUrn = string;

/**
 * Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^RGN_[A-Z]{3}_[A-Z0-9]{2}$
 * @example RGN_STL_99
 */
export type RegionId = string;

/**
 * This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^RGN_[A-Z]{3}_[A-Z0-9]{2}$
 * @example RGN_STL_99
 */
export type RegionPeoplesoftId = string;

/**
 * This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists.
 * @pattern ^[A-Z0-9]{3}$
 * @example 24A
 */
export type RegionLegacyId = string;

/**
 * Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists.
 * @pattern ^[A-Z0-9]{3}$
 * @example 24A
 */
export type RegionOperationalId = string;

/**
 * An object representing a Region in the EHI hierarchy.
 */
export type RegionNode = {
  urn: RegionUrn;

  /** Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  id: RegionId;

  /** This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  peoplesoftId: RegionPeoplesoftId;

  /** This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  legacyId?: RegionLegacyId;

  /** Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  operationalId?: RegionOperationalId;

  /** Legacy Description */
  legacyDescription: LegacyDescription;
};

/**
 * @format urn
 * @pattern ^urn:com.ehi:[a-zA-Z0-9]+:location:group:[A-Z0-9]{5}
 * @example urn:com.ehi:prd:location:group:A0001
 */
export type GroupUrn = string;

/**
 * Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^A[0-9]{4}$
 * @example A0001
 */
export type GroupId = string;

/**
 * Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique.
 * @pattern ^[A-Z0-9]{5}$
 * @example A0001
 */
export type GroupPeoplesoftId = string;

/**
 * This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists.
 * @pattern ^[0-9A-Z]{2}$
 * @example 01
 */
export type GroupLegacyId = string;

/**
 * Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists.
 * @pattern ^[0-9A-Z]{2}$
 * @example 01
 */
export type GroupOperationalId = string;

/**
 * A object representing a Group in the EHI hierarchy.
 */
export type GroupNode = {
  urn: GroupUrn;

  /** Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  id: GroupId;

  /** Colloquially known as the PeopleSoft ID. This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  peoplesoftId?: GroupPeoplesoftId;

  /** This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  legacyId?: GroupLegacyId;

  /** Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  operationalId: GroupOperationalId;

  /** Legacy Description */
  legacyDescription: LegacyDescription;
};

/**
 * SVP or Pod for the group
 * @example CORPORATE
 */
export enum ResponsibleParty {
  CORPORATE = 'CORPORATE',
  LICENSEE_ASISAPAC = 'LICENSEE_ASISAPAC',
  LICENSEE_CANADA = 'LICENSEE_CANADA',
  LICENSEE_EMEA = 'LICENSEE_EMEA',
  LICENSEE_LAC = 'LICENSEE_LAC',
  LICENSEE_US = 'LICENSEE_US',
  TEAM_CANADA = 'TEAM_CANADA',
  TEAM_FRANCE = 'TEAM_FRANCE',
  TEAM_GERMANY = 'TEAM_GERMANY',
  TEAM_IRELAND = 'TEAM_IRELAND',
  TEAM_NORTH_CENTRAL = 'TEAM_NORTH_CENTRAL',
  TEAM_NORTHEAST = 'TEAM_NORTHEAST',
  TEAM_SOUTH_CENTRAL = 'TEAM_SOUTH_CENTRAL',
  TEAM_SOUTHEAST = 'TEAM_SOUTHEAST',
  TEAM_SPAIN = 'TEAM_SPAIN',
  TEAM_UK = 'TEAM_UK',
  TEAM_WEST_COAST = 'TEAM_WEST_COAST',
}

/**
 * An object representing an Area.
 */
export type Area = {
  urn: AreaUrn;

  /** Colloquially known as the Peoplesoft ID, this ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  id: AreaId;

  /** Colloquially known as the Peoplesoft ID, this ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. */
  peoplesoftId: AreaPeoplesoftId;

  /** An ID used by the business to identify an Area. */
  legacyId: AreaLegacyId;

  /** Colloquially known as the legacy ID, an ID used by the business to identify an Area. */
  operationalId: AreaOperationalId;

  /** Legacy Description */
  legacyDescription: LegacyDescription;
  branch?: Branches;
};

/**
 * Details of a branch
 */
export type BranchDetails = {
  urn: BranchUrn;

  /** @deprecated This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. Colloquially known as the Peoplesoft ID. This field is being deprecated in favor of using the URN for a branch. */
  id?: BranchPeopleSoftId;

  /** @deprecated This ID is guaranteed to be populated for every member of the hierarchy, and will always be unique. Colloquially known as the Peoplesoft ID. This field is being deprecated in favor of using the URN for a branch. */
  peoplesoftId?: BranchPeopleSoftId;

  /** @deprecated This ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. Deprecated in favor of using Operational ID. */
  legacyId?: BranchLegacyId;

  /** Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  operationalId: BranchOperationalId;

  /** Station ID for the branch used for Odyssey reservation system branches. If the Reservation system is ECARS and the branch is not a Spread, the field will default to 'E1 followed by Legacy Group Branch ID'. If the branch is a Spread, the default value is N/A. */
  stationId?: StationId;

  /**
   * Global Distribution System ID associated with the branch. This field is not available for Spread Branches. This ID is used to set up a branch in the GDS system, a required process for branches looking to take reservations and fulfill rentals in the GDS system.
   *
   */
  gdsId?: GdsId;

  /** Legacy Description */
  legacyDescription?: LegacyDescription;

  /** The branch phase associated with the branch */
  branchPhase?: BranchPhase;

  /**
   * Indicator of whether or not a branch is a licensee location
   * @example true
   */
  licensee: boolean;

  /** The type associated with the branch */
  type: BranchType;

  /** The brand associated with the branch */
  brand?: Brand;

  /**
   * 3-Character airport code for the airport that is associated with the branch.
   * @pattern ^[A-Z]{3}$
   * @example STL
   */
  airportCode?: string;

  /** A string denoting the timezone in which the branch resides. */
  timezone?: TimeZone;
  geocode?: GeoCode;
  geoLocation?: GeoLocation;
  addresses?: Addresses;

  /** Branch phone number */
  phone?: Phone;

  /** Primary or Secondary language for the branch */
  primaryLanguage?: Language;

  /** Primary or Secondary language for the branch */
  secondaryLanguage?: Language;
};

export type BranchesV2 = Array<BranchV2>;

/**
 * Details of a branch
 */
export type BranchV2 = {
  urn: BranchUrn;

  /** Colloquially known as the Legacy ID and group-branch, this ID is typically what the employees in the field will use when referring to a specific location. The ID is guaranteed to be unique for a given level of the hierarchy if it exists. */
  operationalId: BranchOperationalId;

  /** Station ID for the branch used for Odyssey reservation system branches. If the Reservation system is ECARS and the branch is not a Spread, the field will default to 'E1 followed by Legacy Group Branch ID'. If the branch is a Spread, the default value is N/A. */
  stationId?: StationId;

  /**
   * Global Distribution System ID associated with the branch. This field is not available for Spread Branches. This ID is used to set up a branch in the GDS system, a required process for branches looking to take reservations and fulfill rentals in the GDS system.
   *
   */
  gdsId?: GdsId;

  /** Legacy Description */
  operationalDescription?: LegacyDescription;

  /** The branch phase associated with the branch */
  branchPhase?: BranchPhase;
  names?: Names;

  /** Indicates whether or not the branch is the primary branch for its station among branches that share its station ID. */
  primaryStation?: PrimaryStation;

  /**
   * The branch description mainly used by Peoplesoft.
   * @example AREA A SPREAD 53SA
   */
  legacyDescription?: string;

  /**
   * Indicator of whether or not a branch is a licensee location
   * @example true
   */
  licensee: boolean;
  licenseeConfiguration?: LicenseeConfiguration;

  /** The type associated with the branch */
  type: BranchType;

  /** The brand associated with the branch */
  brand?: Brand;
  airport?: AirportBranch;

  /**
   * 3-Character airport code for the airport that is associated with the branch.
   * @pattern ^[A-Z]{3}$
   * @example STL
   */
  airportCode?: string;
  capabilities?: BusinessTypes;

  /** A string denoting the timezone in which the branch resides. */
  timezone?: TimeZone;
  geoLocation?: GeoLocation;
  indicators?: Indicators;

  /**
   * Indicates that the branch is active within the Global Distribution System and can be used to fulfill 3rd party rentals.
   * @example true
   */
  isActiveInGlobalDistributionSystem?: boolean;
  odysseyConfiguration?: OdysseyConfiguration;

  /** Contains information describing operational profile of the location, such as inside a hotel, a remarketing department inside a building, or the inside of a body shop. */
  operationalLocationType?: OperationalLocationType;
  operationalModel?: OperationalModel;
  addresses?: AddressesV2;
  phones?: PhonesV2;

  /**
   * The Primary Email Address of the Branch
   * @pattern ^[-a-zA-Z0-9_`~!#$%&*{|}+/=?'^]+([.][-a-zA-Z0-9`~!#$%&*{|}+/=?_'^]+)*@[a-zA-Z0-9](-?[a-zA-Z0-9])*([.][a-zA-Z0-9](-?[a-zA-Z0-9])*){1,3}$
   * @example ATLManagers@ehi.com
   */
  primaryEmail?: string;

  /** Primary or Secondary language for the branch */
  primaryLanguage?: Language;

  /** Indicates what line of business the regional administrative office supports. This field is only present for branches with a type of REGIONAL ADMINISTRATIVE */
  regionalAdministrativeSupport?: RegionalAdminSupport;

  /** Primary or Secondary language for the branch */
  secondaryLanguage?: Language;
  serviceLevel?: ServiceLevel;
};

/**
 * An object representing the hierarchy of a branch.
 */
export type BranchHierarchy = {
  /** An object representing a branch. */
  branch: Branch;

  /** An object representing an Area in the EHI hierarchy. */
  area: AreaNode;

  /** An object representing a Sub-Region in the EHI hierarchy. */
  city?: CityNode;

  /** An object representing a Sub-Region in the EHI hierarchy. */
  subregion?: SubRegionNode;

  /** An object representing a Region in the EHI hierarchy. */
  region?: RegionNode;

  /** A object representing a Group in the EHI hierarchy. */
  group: GroupNode;

  /** SVP or Pod for the group */
  responsibleParty?: ResponsibleParty;
};

/**
 * An object representing the hierarchy of an Area.
 */
export type AreaHierarchy = {
  /** An object representing an Area. */
  area: Area;

  /** An object representing a Sub-Region in the EHI hierarchy. */
  city?: CityNode;

  /** An object representing a Sub-Region in the EHI hierarchy. */
  subregion?: SubRegionNode;

  /** An object representing a Region in the EHI hierarchy. */
  region?: RegionNode;

  /** A object representing a Group in the EHI hierarchy. */
  group: GroupNode;

  /** SVP or Pod for the group */
  responsibleParty?: ResponsibleParty;
};
/**
 * Country Subdivisions
 */
export type CountryDetails = {
  /** @example Afghanistan */
  name?: string;

  /** @example AF */
  countryIso2Code?: string;

  /** @example AFG */
  countryIso3Code?: string;
  currency?: CountryCurrency;

  /** Units of measure associated with the specified country. */
  unitsOfMeasure?: UnitsOfMeasure;
};

export type CountryCurrency = {
  /** The ISO-3 Currency code */
  code?: CurrencyCode;

  /** @example Afghani */
  description?: string;
};

/**
 * The ISO-3 Currency code
 * @example AFA
 */
export type CurrencyCode = string;

/**
 * The system of measurement that a given country uses.
 * @example MILES
 */
export enum DistanceUnitOfMeasure {
  MILES = 'MILES',
  KILOMETERS = 'KILOMETERS',
}

/**
 * The system of measurement for volume that a given country uses.
 * @example LITERS
 */
export enum VolumeUnitOfMeasure {
  GALLONS = 'GALLONS',
  LITERS = 'LITERS',
}

/**
 * Units of measure associated with the specified country.
 */
export type UnitsOfMeasure = {
  /** The system of measurement that a given country uses. */
  distance?: DistanceUnitOfMeasure;

  /** The system of measurement for volume that a given country uses. */
  volume?: VolumeUnitOfMeasure;
};

export type Countries = Array<CountryDetails>;

/**
 * Country subdivision type
 * @example STATE
 */
export enum CountrySubdivisionType {
  DISTRICT = 'DISTRICT',
  PREFECTURE = 'PREFECTURE',
  REGION = 'REGION',
  CITY = 'CITY',
  GOVERNORATE = 'GOVERNORATE',
  DEPARTMENT = 'DEPARTMENT',
  TERRITORY = 'TERRITORY',
  EMIRATE = 'EMIRATE',
  PROVINCE = 'PROVINCE',
  COUNTY = 'COUNTY',
  LANDER = 'LANDER',
  DIVISION = 'DIVISION',
  STATE = 'STATE',
  ZONE = 'ZONE',
  REPUBLIC = 'REPUBLIC',
}

export type CountrySubdivision = {
  /** @example CHA */
  shortName?: string;

  /** @example Channel Islands */
  longName?: string;

  /** @example CHA */
  abbreviation?: string;

  /** @example Channel Islands */
  name?: string;

  /** Country subdivision type */
  type?: CountrySubdivisionType;
};

export type Country = CountryDetails & { subdivision?: CountrySubdivision[] };

/**
 * @format urn
 * @pattern ^urn:com.ehi:[a-zA-Z]+:location:station:[A-Z0-9]{6}
 * @example urn:com.ehi:prd:location:station:E10101
 */
export type StationUrn = string;

/**
 * Name of the counter
 * @example MAIN
 */
export type CounterName = string;

/**
 * @pattern ^[0-9]$
 * @example 1
 */
export type CounterOrdinal = string;

/**
 * Determines if this counter has walkin facility
 * @example true
 */
export type CounterWalkin = boolean;

/**
 * An object representing the station counter
 */
export interface Counter {
  /** Name of the counter */
  name?: CounterName;
  branch?: BranchUrn;

  /** Determines if this counter has walkin facility */
  allowWalkins?: CounterWalkin;
  ordinal?: CounterOrdinal;
}

/**
 * An object representing the station counters
 */
export interface Station {
  urn?: StationUrn;

  /**
   * List of branch urns.
   * @example ["urn:com.ehi:prd:location:branch:1000001","urn:com.ehi:prd:location:branch:1000002","urn:com.ehi:prd:location:branch:1000003"]
   */
  branches?: BranchUrn[];
  primaryBranch?: BranchUrn;

  /** List of station counters */
  counters?: Counter[];
}
