import { CircularProgress } from '@mui/material';
import { PageSpinnerContainer } from 'components/shared/ui/spinner/spinner.styles';

export const PageSpinner = (): JSX.Element => {
  return (
    <PageSpinnerContainer>
      <CircularProgress data-testid={'loadingCircle'} />
    </PageSpinnerContainer>
  );
};
