import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { DateTime } from 'luxon';
import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormTextFieldProps } from 'components/shared/forms/FormTextField';
import { useTranslations } from 'components/shared/i18n';
import { EhiTextField } from 'components/shared/forms/EhiTextField';

export const TimePickerField: FunctionComponent<
  Omit<FormTextFieldProps, 'onChange' | 'onError' | 'rules'> & { submitOnChange?: (value: DateTime | '') => void }
> = ({ submitOnChange, ...rest }) => {
  const { t } = useTranslations();
  const form = useFormContext();

  return (
    <Controller
      control={form.control}
      {...rest}
      render={({ field, fieldState }) => (
        <DesktopTimePicker
          {...field}
          inputFormat={t('format.time')}
          mask='__:__ _M'
          value={field.value ? DateTime.fromISO(field.value) : null}
          onChange={(time: DateTime | null) => {
            form.setValue(rest.name, time || null, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });

            if (submitOnChange && time) {
              submitOnChange(time);
            }
          }}
          renderInput={(inputFieldProps) => {
            const { ref, ...fieldProps } = inputFieldProps;

            return (
              <EhiTextField
                {...fieldProps}
                name={field.name}
                fieldRef={ref}
                required={rest.required}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                onBlur={() => {
                  form.trigger(field.name);
                  if (submitOnChange && field.value?.isValid) {
                    submitOnChange(field.value || '');
                  }
                }}
                sx={{ svg: { paddingRight: '12px' } }}
              />
            );
          }}
        />
      )}
    />
  );
};
