import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { BaseDateValidationProps } from '@mui/x-date-pickers/internals/hooks/validation/models';

import { DateTime } from 'luxon';
import { FunctionComponent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormTextFieldProps } from 'components/shared/forms/FormTextField';
import { useTranslations } from 'components/shared/i18n';
import { EhiTextField } from 'components/shared/forms/EhiTextField';

export const DatePickerField: FunctionComponent<
  Omit<FormTextFieldProps, 'onChange' | 'onError' | 'rules'> &
    BaseDateValidationProps<DateTime> & { submitOnChange?: (value: DateTime | '') => void }
> = ({ submitOnChange, disablePast, disableFuture, ...rest }) => {
  const { t } = useTranslations();

  const form = useFormContext();

  return (
    <Controller
      control={form.control}
      {...rest}
      render={({ field, fieldState }) => (
        <DesktopDatePicker
          {...field}
          inputFormat={t('format.date')}
          mask={'__/__/____'}
          value={field.value ? DateTime.fromISO(field.value) : null}
          disableFuture={disableFuture}
          disablePast={disablePast}
          onChange={async (date: DateTime | null, keyboardInputValue?: string) => {
            // avoid validation on typing because every keystroke makes the tablet keyboard disappear and reappear
            const shouldValidateSelectedDate = !keyboardInputValue && date !== null && !!submitOnChange;

            form.setValue(rest.name, date || null, {
              shouldValidate: shouldValidateSelectedDate,
              shouldTouch: true,
              shouldDirty: true,
            });

            const shouldSubmitOnChange =
              !!submitOnChange &&
              // don't submit with errors
              !form.formState.errors?.hasOwnProperty(rest.name) &&
              // keyboard input will be handled in onBlur
              !keyboardInputValue &&
              date !== null;

            if (shouldSubmitOnChange) {
              submitOnChange(date || '');
            }
          }}
          renderInput={(inputFieldProps) => {
            const { ref, ...fieldProps } = inputFieldProps;

            return (
              <EhiTextField
                {...fieldProps}
                name={field.name}
                fieldRef={ref}
                required={rest.required}
                error={!!fieldState.error?.message}
                helperText={fieldState.error?.message}
                onBlur={() => {
                  // handles submitting keyboard inputs
                  form.trigger(field.name);
                  const shouldSubmitOnChange = !!submitOnChange && field.value?.isValid;
                  if (shouldSubmitOnChange) {
                    submitOnChange(field.value || '');
                  }
                }}
                sx={{ svg: { paddingRight: '12px' } }}
              />
            );
          }}
          {...rest}
        />
      )}
    />
  );
};
