import { FunctionComponent } from 'react';

import { Title } from 'components/shared/ui/styles/Typography.styles';
import { StyledCardHeader } from 'components/shared/ui/card/Card.styles';
import { CardHeaderProps } from 'components/shared/ui/card/cardProps';

export const CardHeader: FunctionComponent<CardHeaderProps> = ({ title, titleAdornment, ...rest }) => {
  const titleContent = title && (
    <Title color={rest.onClick ? 'primary' : 'textPrimary'}>
      {title}
      {titleAdornment}
    </Title>
  );

  return <StyledCardHeader title={titleContent} className={rest.className} />;
};
