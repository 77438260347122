import { EhiButtonProps } from '@ehi/ui';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { FunctionComponent, memo, useContext } from 'react';
import { CardContainer, FlexEndItem, FlexStartItem } from 'components/shared/ui/card/CardNavigation.styles';
import { ResSummaryDrawerContext } from 'context/ResSummaryDrawerContext';

export interface CardNavigationProps {
  showExitToRentalSummary?: boolean;
  onPrevious?: () => void;
  onNext?: () => void;
  hideOnNext?: boolean;
  customNextButton?: FunctionComponent<EhiButtonProps>;
}

export const CardNavigation: FunctionComponent<CardNavigationProps> = memo(
  ({ onPrevious, onNext, showExitToRentalSummary, hideOnNext, customNextButton }) => {
    const { openDrawer, setOpenDrawer } = useContext(ResSummaryDrawerContext);
    return (
      <>
        <CardContainer>
          <FlexStartItem>
            {showExitToRentalSummary && (
              <Button startIcon={<ListAltOutlinedIcon />} onClick={() => setOpenDrawer(!openDrawer)}>
                {'Reservation Summary'}
              </Button>
            )}
          </FlexStartItem>
          <FlexEndItem>
            {onPrevious && (
              <Button startIcon={<ArrowBackIos />} onClick={onPrevious}>
                {'Previous'}
              </Button>
            )}
            {onNext && !customNextButton && (
              <Button
                endIcon={<ArrowForwardIos />}
                onClick={onNext}
                style={{ visibility: !hideOnNext ? 'visible' : 'hidden' }}>
                {'Next'}
              </Button>
            )}
            {onNext && customNextButton && customNextButton({ onClick: onNext })}
          </FlexEndItem>
        </CardContainer>
        <Divider />
      </>
    );
  }
);
