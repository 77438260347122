import { RootState } from 'redux/store';
import { DriverData } from 'components/shared/uiModels/driver/driverData';
import { formatName } from 'services/renter/driverTransformer';

export const selectDriverData = ({ driverData }: RootState): DriverData => {
  return driverData.driver;
};

export const selectRenterFullName = ({ driverData }: RootState): string => {
  return formatName(driverData.driver?.lastName, driverData.driver?.firstName);
};

export const selectContactPhone = ({ driverData }: RootState) => {
  return driverData?.driver?.phone;
};
