import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/material';

export const StyledCard = styled(Card)(({ theme, onClick }) => ({
  margin: theme.spacing(1, 1, 2, 1),
  cursor: onClick ? 'pointer' : 'unset',
}));

export const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  padding: theme.spacing(0),
}));

export const CardContentWithoutHeader = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}));
