import { Body1, Body2 } from '@ehi/ui';
import { Box, Grid } from '@mui/material';
import { useTranslations } from 'components/shared/i18n';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';

export const BillToSection = () => {
  const { t } = useTranslations();
  return (
    <Box>
      <Body1 bold>{t('rateAndBilling.billTo')}</Body1>
      <Grid container>
        <Grid item xs={6}>
          <Caption2 display={'inline'}>{'Account Number '}</Caption2>
          <Body2 bold display={'inline'}>
            {'--'}
          </Body2>
        </Grid>
        <Grid item xs={6}>
          <Caption2 display={'inline'}>{'Billing Number '}</Caption2>
          <Body2 bold display={'inline'}>
            {'--'}
          </Body2>
        </Grid>
      </Grid>
    </Box>
  );
};
