import { TextFieldProps } from '@mui/material';
import { Controller, FieldValues, Path, useFormContext } from 'react-hook-form';
import { EhiTextField } from 'components/shared/forms/EhiTextField';

export type FormTextFieldProps<TFieldValues extends FieldValues = FieldValues> = TextFieldProps & {
  name: Path<TFieldValues>;
};

/**
 * For use within React Hooks forms
 */
export const FormTextField = <T extends FieldValues>({ name, hidden, ...props }: FormTextFieldProps<T>) => {
  const { control } = useFormContext<T>();

  if (hidden) {
    return null;
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const { ref, ...fieldProps } = field;

        return (
          <EhiTextField
            {...fieldProps}
            fieldRef={ref}
            error={!!fieldState.error?.message}
            helperText={fieldState.error?.message}
            {...props}
          />
        );
      }}
    />
  );
};
