import { createElement, FunctionComponent } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexiFunctionComponent';

type FlexComponentProps = {
  component: FlexiFunctionComponent;
  previous: To | undefined;
  next: To | undefined;
};

export const FlexComponent: FunctionComponent<FlexComponentProps> = ({
  component,
  previous,
  next,
}: FlexComponentProps) => {
  const navigate = useNavigate();

  return createElement(component, {
    onPrevious: previous ? () => navigate(previous, { replace: true }) : undefined,
    onNext: next ? () => navigate(next, { replace: true }) : undefined,
  });
};
