import { logException } from 'components/shared/logger/splunkLogger';
import { ErrorResponse, isRouteErrorResponse } from 'react-router-dom';
import { EhiErrors } from 'services/EhiErrors';

export const isThrownError = (error: unknown): error is Error => {
  return (error as Error).message !== undefined;
};

export const isEhiError = (it: unknown): it is EhiErrors => {
  return (it as EhiErrors)?.errors !== undefined;
};

// Will rethrow if not EhiErrors
export const safelyCatchError = (e: any): EhiErrors => {
  if (isEhiError(e)) {
    return e;
  } else {
    throw e;
  }
};

export const getHandledErrorMessage = (e: unknown): string | undefined => {
  let errorMessage;
  if (typeof e === 'string') {
    errorMessage = e.toUpperCase();
  } else if (e instanceof Error) {
    errorMessage = e.message;
  }
  return errorMessage;
};

export type ErrorType = Error & {
  logged: boolean;
};

export const handleError = (error: any) => {
  if (error) {
    if (isThrownError(error)) {
      const errorType = error as ErrorType;
      if (!errorType?.logged) {
        errorType.logged = true;
        // We have to manually add the message and stack field because the analytics library spreads the exception object
        // we pass in. message and stack attributes are non-enumerable fields, and so they are lost when using the spread
        // operator in some browsers (see
        // https://stackoverflow.com/questions/59874163/object-spread-an-error-results-in-no-message-property)
        logException({
          exception: { ...errorType, message: errorType.message, stack: errorType.stack },
        });
      }
    } else if (isRouteErrorResponse(error)) {
      const errorType = error as ErrorResponse & { logged: boolean };
      if (!errorType?.logged) {
        errorType.logged = true;
        logException({
          exception: {
            ...errorType,
            message: errorType.data.message ?? 'Error occurred',
            error: errorType,
            statusText: errorType.statusText,
            status: errorType.status,
          },
        });
      }
    } else {
      logException({
        exception: { message: 'Unknown error occurred', error: error },
      });
    }
  }
};
