import { enUS, frCA } from '@ehi/location';

export enum CorporateCountry {
  UnitedStates = 'USA',
  Canada = 'CAN',
  GreatBritain = 'GBR',
  Ireland = 'IRL',
  Spain = 'ESP',
  Germany = 'DEU',
  France = 'FRA',
}

export const CORPORATE_COUNTRIES = Object.values(CorporateCountry);

export function isNACountry(countryIso3: string) {
  return countryIso3 === enUS.iso3Country || countryIso3 === frCA.iso3Country;
}
