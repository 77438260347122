import { ResponseMessage } from 'services/ResponseMessage';
import { CardProps } from 'components/shared/ui/card/cardProps';
import { SummaryCardStyled } from 'components/resSummary/ReservationSummary.styles';
import { LoadableView, LoadingState } from 'components/loadableView/LoadableView';
import { CardContentWithoutHeader } from 'components/shared/ui/card/Card.styles';
import { Placeholder } from 'components/shared/Placeholder';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';

type ReservationSummaryCardProps = CardProps & {
  loading?: boolean;
  errors?: ResponseMessage[];
};

export const ReservationSummaryCard = ({ loading, children, ...rest }: ReservationSummaryCardProps): JSX.Element => {
  if (loading) {
    rest.title = undefined;
  }

  return (
    <SummaryCardStyled variant={'outlined'} {...rest}>
      <LoadableView
        state={loading ? LoadingState.LOADING : LoadingState.SUCCESS}
        errorComponent={<ErrorWrapper />}
        loadingComponent={
          <CardContentWithoutHeader>
            <Placeholder rows={3} columns={2} withHeader />
          </CardContentWithoutHeader>
        }>
        {children}
      </LoadableView>
    </SummaryCardStyled>
  );
};
