import { useCallback } from 'react';
import { LocationAndArea } from 'services/location/locationRollupTypes';
// import { useNavigate } from 'react-router-dom';
import { useLocale } from 'components/shared/i18n';
import { updateDefaultAttributes } from 'components/shared/logger/splunkLogger';
import { useLdProvider } from 'components/shared/launchDarkly/LaunchDarklyService';
import { FeatureFlagState, initLDFeatureFlags, selectOverriddenFeatureFlags } from 'redux/slices/featureFlagsSlice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { convertLdFeatureFlags } from 'utils/featureFlagUtils';
import { useLocationRollUpService } from 'services/location/useLocationRollUpService';
import { EhiCounter } from '@ehi/location';

export type EhiLocationHook = {
  doFetchLocation: (locationId: string) => Promise<{
    location: LocationAndArea;
    peopleSoftId: string;
    stationId: string;
    groupBranch: string;
    counters: EhiCounter[];
  }>;
  validateLocation: (locationArea: LocationAndArea) => Promise<boolean>;
};
export const useEhiLocation = (): EhiLocationHook => {
  const { initLocaleForLocation } = useLocale();
  const { identifyUser } = useLdProvider();
  const { locationFeatureFlagEnabled } = useAppSelector(selectOverriddenFeatureFlags);
  const dispatch = useAppDispatch();
  const overriddenFlags = useAppSelector(selectOverriddenFeatureFlags);
  const { queryLocationAndArea } = useLocationRollUpService();

  const doFetchLocation = useCallback(
    async (locationId: string) => {
      const locationRollup = await queryLocationAndArea(locationId);
      return {
        location: locationRollup,
        peopleSoftId: locationRollup.location.id,
        stationId: locationRollup.location.stationId,
        groupBranch: locationRollup.location.groupBranch,
        counters: locationRollup.location.counters,
      };
    },
    [queryLocationAndArea]
  );

  const validateLocation = useCallback(
    async (locationArea: LocationAndArea) => {
      const newUserFlags = await identifyUser({
        groupBranchId: locationArea.location.groupBranch,
        areaPeoplesoftId: locationArea.area.id,
        peoplesoftId: locationArea.location.id,
        countryCode: locationArea.location.locale.iso3Country,
      });

      if (locationFeatureFlagEnabled !== FeatureFlagState.ENABLED && newUserFlags !== undefined) {
        if (newUserFlags['rpu-location-enabled'] === false) {
          return false;
        } else {
          await initLocaleForLocation({
            branchIso2Language: locationArea.location.locale.iso2Language,
            branchIso2Country: locationArea.location.locale.iso2Country,
          });
          updateDefaultAttributes();
          dispatch(
            initLDFeatureFlags({
              ldFlags: convertLdFeatureFlags(newUserFlags),
              overriddenFlags: overriddenFlags,
            })
          );
          return true;
        }
      } else {
        await initLocaleForLocation({
          branchIso2Language: locationArea.location.locale.iso2Language,
          branchIso2Country: locationArea.location.locale.iso2Country,
        });
        updateDefaultAttributes();
        return true;
      }
    },
    [identifyUser, locationFeatureFlagEnabled, initLocaleForLocation, dispatch, overriddenFlags]
  );
  return { doFetchLocation, validateLocation };
};
