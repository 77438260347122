import { UseQueryOptions } from '@tanstack/react-query';

export const DEFAULT_CACHE_TIME = 0;
export const FIFTEEN_HOURS = 1000 * 60 * 60 * 15; // 15 hours in milliseconds
export const INFINITY = Infinity;

type Options = Readonly<Pick<UseQueryOptions, 'cacheTime' | 'staleTime'>>;

export const DEFAULT_CACHE_OPTIONS: Options = {
  staleTime: DEFAULT_CACHE_TIME,
  cacheTime: DEFAULT_CACHE_TIME,
};

export const DOMAIN_CACHE_OPTIONS: Options = {
  staleTime: FIFTEEN_HOURS,
  cacheTime: FIFTEEN_HOURS,
};

export const SESSION_CACHE_OPTIONS: Options = {
  staleTime: INFINITY,
  cacheTime: INFINITY,
};
