import { Card } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexiFunctionComponent';
import { CardHeader, Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1, Body2 } from '@ehi/ui';

export const Driver: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  return (
    <Card>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <CardContent>
        <Card>
          <CardHeader title={'Driver'}></CardHeader>
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Caption2>{'Contact'}</Caption2>
                <Body1 bold>{'Robert, Richard'}</Body1>
                <Body2>
                  {'Mobile/Cell'} {':'} {'(512) 867-5309'}
                </Body2>
                <Body2>
                  {'Email'} {':'} {'eldiablo26@aol.com'}
                </Body2>
              </Grid>
              <Grid item xs={6}>
                <Caption2>{'Address'}</Caption2>
                <Body2>{'742 Evergreen Terrace'}</Body2>
                <Body2>{'kirkood, MO 63122'}</Body2>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};
