import { DateTime } from 'luxon';
export const toDateTime = (mixed?: string | DateTime | Date | null, zone?: string): DateTime | undefined => {
  if (!mixed) {
    return undefined;
  }
  let dateTime: DateTime;

  if (typeof mixed === 'string') {
    dateTime = DateTime.fromISO(mixed);
  } else if (DateTime.isDateTime(mixed)) {
    dateTime = mixed;
  } else if (mixed instanceof Date) {
    dateTime = DateTime.fromJSDate(mixed);
  } else {
    return undefined;
  }

  if (zone) {
    return dateTime.setZone(zone);
  }
  return dateTime;
};

export const toDateTimeString = (dateTime?: string | DateTime, format?: string): string | null | undefined => {
  const dateTimeObject = toDateTime(dateTime);
  if (format) {
    return dateTimeObject?.toFormat(format);
  } else {
    return dateTimeObject?.toISO({ suppressMilliseconds: true });
  }
};
