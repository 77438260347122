import { FC } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useTranslations } from 'components/shared/i18n';
import { DriverFields, DriverValues } from './DriverTypes';
import { GridContainer, GridItem } from 'components/shared/ui/styles/Grid.styles';
import { FormTextField } from 'components/shared/forms/FormTextField';
import { CountrySelectField } from 'components/shared/forms/CountrySelectField';
import { SelectField } from 'components/shared/forms/SelectField';
import { Card } from '@mui/material';
import { PhoneTextField } from 'components/shared/forms/PhoneTextField';
import * as Yup from 'yup';
import { useYupValidationResolver } from 'components/shared/forms/useYupValidationResolver';

import { loadEhiLocationCookie } from '@ehi/location';
import { DividerWithMargin } from 'components/shared/ui/styles/Divider.styles';
import { AddCircle, Article, NavigateNext } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { MaxLengthTextField } from 'components/shared/forms/MaxLengthTextField';
import { CountrySubDivisionSelectField } from 'components/shared/forms/CountrySubDivisionSelectField';
import { CorporateCountry } from 'utils/countryUtils';
import { piiField } from '@ehi/support';
import { useBranchInfoByPeopleSoftIdQuery } from 'services/location/locationQueries';
import { extractCountryCode } from 'utils/locationUtils';

export const ModifyDriver: FC = () => {
  const { t } = useTranslations();
  const handleSubmit = (data: DriverValues) => console.log('submitted', data);
  const validationSchema = Yup.object().shape({
    lastName: Yup.string().required(t('validation.requiredField')),
    firstName: Yup.string().required(t('validation.requiredField')),
    age: Yup.number()
      .positive('Age must be a positive number')
      .integer('Age must be an integer')
      .required(t('validation.requiredField')),
    country: Yup.string().required(t('validation.requiredField')),
    streetAddress1: Yup.string().required(t('validation.requiredField')),
    streetAddress2: Yup.string(),
    state: Yup.string().required(t('validation.requiredField')),
    city: Yup.string().required(t('validation.requiredField')),
    postalCode: Yup.string().required(t('validation.requiredField')),
    contactType: Yup.string().required(t('validation.requiredField')),
    countryCode: Yup.string().required(t('validation.requiredField')),
    phone: Yup.number().typeError('Phone must be a number').required(t('validation.requiredField')),
  });

  const initialValues: DriverValues = {
    lastName: '',
    firstName: '',
    age: undefined,
    country: '',
    streetAddress1: '',
    streetAddress2: '',
    state: '',
    city: '',
    postalCode: '',
    contactType: '',
    countryCode: '',
    phone: '',
  };
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm<DriverValues>({ resolver: resolver, defaultValues: initialValues });
  const onFormSubmit = methods.handleSubmit(handleSubmit);
  return (
    <Card>
      <FormProvider {...methods}>
        <ModifyDriverContainer onFormSubmit={onFormSubmit} />
      </FormProvider>
    </Card>
  );
};
type ModifyDriverContainerProps = {
  loading?: boolean;
  onFormSubmit: () => void;
};
const ModifyDriverContainer: FC<ModifyDriverContainerProps> = ({ onFormSubmit }) => {
  const { t } = useTranslations();
  const cookie = loadEhiLocationCookie();
  const { setValue } = useFormContext();
  const { data: branchV2 } = useBranchInfoByPeopleSoftIdQuery(cookie?.peoplesoftId ?? '');
  const [country] = useWatch({ name: [DriverFields.Country] });
  return (
    <>
      <GridContainer>
        <GridItem sm={6}>
          <Button startIcon={<Article />}>Reservation Summary</Button>
        </GridItem>
        <GridItem sm={6} sx={{ textAlign: 'end' }}>
          <Button endIcon={<NavigateNext />} onClick={onFormSubmit}>
            Next
          </Button>
        </GridItem>
      </GridContainer>
      <DividerWithMargin />
      <GridContainer>
        <GridItem sm={12}>
          <h3>Driver</h3>
        </GridItem>
        <GridItem sm={4}>
          <MaxLengthTextField
            name={DriverFields.LastName}
            label={t('common.lastName')}
            maxLength={25}></MaxLengthTextField>
        </GridItem>
        <GridItem sm={4}>
          <MaxLengthTextField
            name={DriverFields.FirstName}
            label={t('common.firstName')}
            maxLength={25}></MaxLengthTextField>
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={DriverFields.Age} label={'Age'}></FormTextField>
        </GridItem>
        <GridItem sm={4}>
          <CountrySelectField
            name={DriverFields.Country}
            required
            label={'Country'}
            onChange={(event) => {
              setValue(DriverFields.Country, event.target.value);
              setValue(DriverFields.State, '');
            }}
          />
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={DriverFields.StreetAddress1} label={'Street Address1'}></FormTextField>
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={DriverFields.StreetAddress2} label={'Street Address2'}></FormTextField>
        </GridItem>
        <GridItem sm={4}>
          <FormTextField name={DriverFields.City} label={'City'}></FormTextField>
        </GridItem>
        {country.length > 0 && (country === CorporateCountry.UnitedStates || country === CorporateCountry.Canada) && (
          <GridItem sm={4}>
            <CountrySubDivisionSelectField
              name={DriverFields.State}
              required
              label={country === CorporateCountry.UnitedStates ? 'State' : 'Province'}
              country={country}
              onChange={(event) => {
                setValue(DriverFields.State, event.target.value);
              }}
              className={piiField}
            />
          </GridItem>
        )}
        <GridItem sm={4}>
          <FormTextField name={DriverFields.PostalCode} label={'Postal Code'}></FormTextField>
        </GridItem>
      </GridContainer>
      <DividerWithMargin />
      <GridContainer>
        <GridItem sm={12}>
          <h3>Contact</h3>
        </GridItem>
        <GridItem sm={4}>
          <SelectField
            name={DriverFields.ContactType}
            label={t('Contact Type')}
            options={[{ label: 'Test', value: 'test' }]}
            hasNoneOption={false}
          />
        </GridItem>
        <GridItem sm={4}>
          <SelectField
            name={DriverFields.CountryCode}
            label={'Country Code'}
            options={[{ label: 'Test', value: 'test' }]}
            hasNoneOption={false}
          />
        </GridItem>
        <GridItem sm={4}>
          <PhoneTextField country={extractCountryCode(branchV2?.addresses)} name={DriverFields.Phone} label={'Phone'} />
        </GridItem>
        <GridItem sm={4}>
          <Button startIcon={<AddCircle />}>Add Number</Button>
        </GridItem>
      </GridContainer>
    </>
  );
};
