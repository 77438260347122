import { styled, Tab } from '@mui/material';

export const FlexiAppBar = styled('div')(() => ({
  width: '100%',
}));

export const FlexiTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(0, 1),
  minWidth: '2rem',
  alignSelf: 'baseline',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: 'fit-content',
}));
