import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DriverData } from 'components/shared/uiModels/driver/driverData';

export interface DriverState {
  driver: DriverData;
  // could have loyalty in the future
}
const initialState: DriverState = {
  driver: {} as DriverData,
};

const driverSlice = createSlice({
  name: 'driver',
  initialState: initialState,
  reducers: {
    setDriverData: (state: DriverState, action: PayloadAction<DriverData>) => {
      state.driver = action.payload;
    },
  },
});

export const { setDriverData } = driverSlice.actions;

export default driverSlice.reducer;
