import Tabs from '@mui/material/Tabs';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FunctionComponent, useCallback } from 'react';
import { Path, To, useLocation, useMatches, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { FlexRoute, getFlexDetailsFromMatches } from 'components/shared/flexiFlow/flexRoute';
import { FlexiAppBar, FlexiTab } from 'components/shared/flexiFlow/FlexiFlowMenu.styles';
import { TabProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageStatus, StepIcon } from 'components/shared/progressIndicator/StepIcon';

type FlexiFlowMenuProps = {
  pages: FlexRoute[];
  showDrawer?: () => void;
};

export const NAVIGATION_PARAMETER = 'nav_from';

export const FlexiFlowMenu: FunctionComponent<FlexiFlowMenuProps> = ({ pages }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const matches = useMatches();
  const currentPath = getFlexDetailsFromMatches(matches)?.position;

  return (
    <FlexiAppBar data-testid='flexi-flow'>
      <Tabs
        style={{ width: '100%', justifyContent: 'space-between' }}
        value={currentPath}
        indicatorColor='primary'
        textColor='primary'
        variant={isSmallScreen ? 'scrollable' : 'fullWidth'}
        scrollButtons={isSmallScreen}>
        {pages.map((page: FlexRoute) => (
          <FlexTab page={page} key={page.titleKey} />
        ))}
      </Tabs>
    </FlexiAppBar>
  );
};

const FlexTab: FunctionComponent<TabProps & { page: FlexRoute }> = ({ page, ...props }) => {
  const { t } = useTranslation();
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();

  // ToDo add page statuses hook
  // const iconStatus = usePageStatus(page.path);
  const handleTabClick = useCallback(() => {
    let to: To = '';

    if (page.to) {
      to = { ...page.to } as Partial<Path>;
      if (page.includeBackNavigation) {
        const searchParams = new URLSearchParams(to.search);
        searchParams.append(NAVIGATION_PARAMETER, pathname + (hash || ''));
        to.search = '?' + searchParams.toString();
      }
    } else if (page.path) {
      to = page.path;
      if (page.includeBackNavigation) {
        const searchParams = new URLSearchParams();
        searchParams.append(NAVIGATION_PARAMETER, pathname + (hash || ''));
        to = to + '?' + searchParams.toString();
      }
    }
    navigate(to, { replace: true });
  }, [hash, navigate, page.includeBackNavigation, page.path, page.to, pathname]);

  return (
    <FlexiTab
      // props here is important, Tabs injects TabProps values
      {...props}
      label={t(page.titleKey)}
      icon={<StepIcon status={PageStatus.Incomplete} />}
      key={page.titleKey}
      value={page.position}
      disabled={false}
      className='flexi-tab'
      onClick={handleTabClick}
    />
  );
};
