import { FlexiFunctionComponent } from 'components/shared/flexiFlow/FlexiFunctionComponent';
import { Card } from 'components/shared/ui/card/Card';
import { StickyCardNavigation } from 'components/shared/ui/card/StickyCardNavigation';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1 } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';

export const Payment: FlexiFunctionComponent = ({ onNext, onPrevious }) => {
  const { t } = useTranslations();
  return (
    <Card>
      <StickyCardNavigation onPrevious={onPrevious} onNext={onNext} showExitToRentalSummary={true} />
      <CardContent>
        <Card>
          <CardHeader title={t('common.renter')} />
          <CardContent>
            <Caption2>{t('payment.paymentMethod')}</Caption2>
            <Body1>{'Credit Card'}</Body1>
          </CardContent>
        </Card>
      </CardContent>
    </Card>
  );
};
