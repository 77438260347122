import { FC } from 'react';
import { AppTheme } from 'components/theme/AppTheme';
import { QueryClient } from 'components/shared/react-query/QueryClient';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorWrapper from 'components/shared/errors/ErrorWrapper';
import { handleError } from 'utils/errorUtils';
import { AppRouterProvider } from 'app/router/AppRouterProvider';

const App: FC = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorWrapper} onError={handleError}>
      <QueryClient>
        <AppTheme>
          {/* Everything above here does not need routing data or authentication */}
          <AppRouterProvider />
        </AppTheme>
      </QueryClient>
    </ErrorBoundary>
  );
};
export default App;
