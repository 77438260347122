import { FunctionComponent, memo, useEffect } from 'react';
import { Box } from '@mui/material';
import { CUSTOMER_HEADER_ELEMENT_ID } from 'components/customerHeader/CustomerHeader';
import { DynamicBox, STICKY_NAV_CLASS_NAME } from 'components/shared/ui/card/CardNavigation.styles';
import { CardNavigation, CardNavigationProps } from 'components/shared/ui/card/CardNavigation';
import Divider from '@mui/material/Divider';

const STICKY_WRAPPER_ID = 'card-nav-sticky-wrapper';
const PLACEHOLDER_WRAPPER_ID = 'card-nav-placeholder';

const cardNavScrollListener: EventListener = () => {
  const mainElement = document.getElementsByTagName('main')[0];
  const rentalHeader = document.getElementById(CUSTOMER_HEADER_ELEMENT_ID);
  const navBar = document.getElementById(STICKY_WRAPPER_ID);
  const cardNavPlaceholder = document.getElementById(PLACEHOLDER_WRAPPER_ID);

  if (mainElement && rentalHeader && navBar && cardNavPlaceholder) {
    // find spot where the navigation should "stick" (top of main plus height of rental header)
    const maxTop = mainElement.offsetTop + rentalHeader.offsetHeight;
    const cardTop = cardNavPlaceholder?.getBoundingClientRect().top;

    if (cardTop <= maxTop) {
      if (!navBar.classList.contains(STICKY_NAV_CLASS_NAME)) {
        navBar.classList.add(STICKY_NAV_CLASS_NAME);
        // minus 1 so there isn't a gap
        navBar.style.top = `${maxTop - 1}px`;
      }
    } else {
      if (navBar.classList.contains(STICKY_NAV_CLASS_NAME)) {
        navBar.classList.remove(STICKY_NAV_CLASS_NAME);
        navBar.style.top = 'unset';
      }
    }
  }
};

export const StickyCardNavigation: FunctionComponent<CardNavigationProps> = memo(({ ...props }) => {
  useEffect(() => {
    const mainElement = document.getElementsByTagName('main')[0];
    if (mainElement) {
      mainElement.onscroll = cardNavScrollListener;
    }
  });

  return (
    <Box id={PLACEHOLDER_WRAPPER_ID} minHeight={(theme) => theme.spacing(8)}>
      {/*
        card-nav-placeholder "holds the space" for when card-nav-sticky-wrapper becomes sticky to prevent page jumping
       */}
      <DynamicBox id={STICKY_WRAPPER_ID}>
        <CardNavigation {...props} />
      </DynamicBox>
      <Divider />
    </Box>
  );
});
