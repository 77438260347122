import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { PhoneType } from 'services/renter/renterReferenceTypes';

const RENTER_REFERENCE_ENDPOINT = '/reference/renter';

export const getPhoneTypes = async (): Promise<PhoneType[]> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${RENTER_REFERENCE_ENDPOINT}/phoneType`, {
    genericUrl: `${RENTER_REFERENCE_ENDPOINT}/phoneType`,
  });

  return response.data;
};
