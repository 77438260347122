import { Box, styled } from '@mui/material';
import { Error } from '@mui/icons-material';
import {
  BoxShadow,
  contentContainerBackground,
  pageContainerBackground,
  secondaryText,
} from 'components/shared/ui/styles/Global.styles';
import { ehiTheme } from '@ehi/ui';

export const ErrorContainer = styled(BoxShadow)`
  grid-area: center;
  border-radius: ${ehiTheme.spacing(0.5)};
  background-color: ${contentContainerBackground};
  text-align: center;
  padding: ${ehiTheme.spacing(5)};
  margin: auto;
`;

export const ErrorBody = styled(Box)`
  display: block;
  align-self: center;
  padding-top: ${ehiTheme.spacing(4)};
`;

export const ErrorOverlayContainer = styled(Box)`
  color: ${secondaryText};
  background-color: ${pageContainerBackground};
  width: 100vw;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled('div')`
  color: ${(props) => props.theme.palette.error.main};
  font-size: ${(props) => props.theme.typography.h4.fontSize};
  font-weight: 400;
  margin-bottom: 32px;
`;

export const Body = styled('div')`
  color: ${(props) => props.theme.palette.error.main};
  font-size: ${(props) => props.theme.typography.subtitle1.fontSize};
  max-width: 45ch;
  line-height: 24px;
  text-align: center;
`;

export const Icon = styled(Error)`
  color: ${(props) => props.theme.palette.error.main};
  height: 67px;
  width: 67px;
  margin-bottom: 8px;
`;

export const Container = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
