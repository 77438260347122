import { FunctionComponent } from 'react';
import { CardProps } from 'components/shared/ui/card/cardProps';
import { StyledCard } from 'components/shared/ui/card/Card.styles';
import { CardHeader } from 'components/shared/ui/card/CardHeader';

export const Card: FunctionComponent<CardProps> = ({ title, titleAdornment, children, ...rest }) => {
  return (
    <StyledCard variant={'outlined'} {...rest} role={'region'} aria-label={title}>
      {title && <CardHeader title={title} titleAdornment={titleAdornment} {...rest} />}
      {children}
    </StyledCard>
  );
};
