import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { FunctionComponent, RefObject } from 'react';
import { FieldErrorIcon } from 'components/shared/ui/FieldErrorIcon';

export type EhiTextFieldProps = TextFieldProps & {
  fieldRef?: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null;
  overrideReadOnly?: boolean;
};

export const EhiTextField: FunctionComponent<EhiTextFieldProps> = ({ fieldRef, inputProps, ...props }) => {
  const labelId = `${props.id || inputProps?.id || props.name}-label`;

  return (
    <TextField
      {...props}
      InputLabelProps={{ id: labelId }}
      // field ref allows react-hook-froms and mui work nicely together
      inputProps={{ ...inputProps, ref: fieldRef, name: props.name, 'aria-labelledby': labelId }}
      FormHelperTextProps={{ role: 'status' }}
      InputProps={{
        ...props.InputProps,
        endAdornment: props.error ? (
          <>
            {props.InputProps?.endAdornment}
            <InputAdornment position='end' sx={{ marginLeft: 0, paddingRight: 1 }}>
              <FieldErrorIcon />
            </InputAdornment>
          </>
        ) : (
          props.InputProps?.endAdornment
        ),
      }}
    />
  );
};
