import {
  Contact,
  DriverProfileRenter,
  NoProfileRenter,
  Renter,
  TransactionalProfileRenter,
} from 'services/booking/bookingTypes';
import { parseUrn } from 'utils/urnUtils';
import { getDriverProfile } from 'services/renter/driverProfileService';
import { getTransactionalProfile } from 'services/renter/transactionalProfileService';
import { logError } from 'components/shared/logger/splunkLogger';
import {
  transformToDriverFromDriverProfile,
  transformToDriverFromNoProfile,
  transformToDriverFromTransactionalProfile,
} from 'services/renter/driverTransformer';
import { DriverData, RenterTypes } from 'components/shared/uiModels/driver/driverData';

export const useRenterProfileHelper = () => {
  const getRenterProfile = async (renter?: Renter, contact?: Contact): Promise<DriverData | undefined> => {
    if (RenterTypes.NoProfile === renter?.type) {
      return transformToDriverFromNoProfile(renter as NoProfileRenter, contact);
    } else if (RenterTypes.DriverProfile == renter?.type) {
      const driverProfileRenter = renter as DriverProfileRenter;
      const driverProfile = await getDriverProfile(parseUrn(driverProfileRenter.profile));
      return transformToDriverFromDriverProfile(driverProfile);
    } else if (RenterTypes.TransactionalProfile == renter?.type) {
      const transactionalProfileRenter = renter as TransactionalProfileRenter;
      const transactionalProfile = await getTransactionalProfile(parseUrn(transactionalProfileRenter.profile));
      return transformToDriverFromTransactionalProfile(transactionalProfile);
    } else {
      logError({ message: 'No Renter Information From Booking' });
      return undefined;
    }
  };

  return {
    getRenterProfile,
  };
};
