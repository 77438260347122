import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid, IconButton } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1, Body2 } from '@ehi/ui';
import { useBranchInfoByUrnQuery } from 'services/location/locationQueries';
import { toDateTimeString } from 'utils/dateUtils';
import { useTranslations } from 'components/shared/i18n';
import { ArrowRightAlt } from '@mui/icons-material';
import { useAppSelector } from 'redux/hooks';
import { selectPickup, selectReturn } from 'redux/selectors/bookingEditor';

export const WhenAndWhereSummary = (): JSX.Element => {
  const { t } = useTranslations();
  const pickupData = useAppSelector(selectPickup);
  const returnData = useAppSelector(selectReturn);

  const { data: pickupBranch } = useBranchInfoByUrnQuery(pickupData?.branch ?? '');
  const { data: returnBranch } = useBranchInfoByUrnQuery(returnData?.branch ?? '');

  const formattedPickupDate = toDateTimeString(pickupData?.dateTime, t('format.shortDateTime'));
  const formattedReturnDate = toDateTimeString(returnData?.dateTime, t('format.shortDateTime'));

  return (
    <ReservationSummaryCard title={t('reservationSummary.whenWhere')} loading={false}>
      <CardContent>
        <Grid container direction={'column'}>
          <Grid item xs width={'100%'}>
            <Caption2>{'Rental Start'}</Caption2>
            <div>
              <Body1 bold display='inline' noWrap={true}>
                {formattedPickupDate ?? 'Mon, 7/12/23 2:30PM'}
              </Body1>
            </div>
            <Body2 whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>
              {pickupBranch?.operationalDescription ?? 'STLT61 St. Louis Lambert International Airport'}
            </Body2>
          </Grid>
          <Grid item xs width={'100%'}>
            <Caption2>
              {'Rental Return'}
              <IconButton>
                <ArrowRightAlt fontSize='small' color='secondary' />
              </IconButton>
            </Caption2>
            <div>
              <Body1 bold display='inline' noWrap={true}>
                {formattedReturnDate ?? 'Fri, 8/10/23 5:30PM (28 days)'}
              </Body1>
            </div>
            <Body2 whiteSpace={'nowrap'} overflow={'hidden'} textOverflow={'ellipsis'}>
              {returnBranch?.operationalDescription ?? 'BNAT61 Nashville International Airport'}
            </Body2>
          </Grid>
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
