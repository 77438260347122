import axios, { AxiosInstance } from 'axios';
import {
  loggingErrorInterceptor,
  loggingRequestInterceptor,
  loggingResponseInterceptor,
} from 'services/networkLoggingUtil';
import { getAppConfigCache } from 'services/appConfig/appConfigService';
import { ehiHeaderInterceptor } from 'services/interceptors/ehiHeaderInterceptor';

let defaultAxiosInstance: AxiosInstance | undefined;

export const createDefaultInstance = () => {
  const requestConfig = {
    responseType: 'json',
    transitional: {
      clarifyTimeoutError: true,
      forcedJSONParsing: true,
      silentJSONParsing: true,
    },
    baseURL: getAppConfigCache()?.serviceEndpoints.superEdge.url ?? '',
  };

  defaultAxiosInstance = axios.create(requestConfig);

  // Setting the default timeout to be 20 seconds, so we match SAT standards
  defaultAxiosInstance.defaults.timeout = 20000;

  // Kong routing does not like the current default that contains multiple accept types.
  // setting the default to this generic value allows proper routing without getting content type not supported errors
  defaultAxiosInstance.defaults.headers.common.Accept = '*/*';

  defaultAxiosInstance.interceptors.request.use(ehiHeaderInterceptor);

  defaultAxiosInstance.interceptors.request.use(loggingRequestInterceptor);
  defaultAxiosInstance.interceptors.response.use(loggingResponseInterceptor, async (error) => {
    await loggingErrorInterceptor(error);
    throw error;
  });
};

export const getDefaultAxiosInstance = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return defaultAxiosInstance!;
};
