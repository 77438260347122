import { Dispatch, SetStateAction, createContext } from 'react';
import { Secrets } from 'services/secrets/secretsService';
interface SecretsContextProps {
  secrets: Secrets;
  setSecretsState: Dispatch<SetStateAction<Secrets>>;
}

export const secretsInitialState = {
  reservationsDevPendoKey: '',
  reservationsDevSupportKey: '',
  reservationsDevLaunchDarklyKey: '',
  reservationsGcsApiKey: '',
};
export const SecretsContext = createContext<SecretsContextProps>({
  secrets: secretsInitialState,
  setSecretsState: () => undefined,
});
