import { FunctionComponent, useMemo } from 'react';
import { SelectField, SelectFieldProps } from 'components/shared/forms/SelectField';
import { useSubdivisionsQuery } from 'services/location/locationQueries';
import { Option } from 'components/shared/ui/Option';
import { generateAllCountrySubDivisionMenuItems } from 'utils/formUtils';

export const CountrySubDivisionSelectField: FunctionComponent<
  Omit<SelectFieldProps, 'options'> & { country: string }
> = ({ country, ...props }) => {
  const { data } = useSubdivisionsQuery(country);
  const options = useMemo<Option<string>[]>(() => {
    if (!data?.length) return [];

    return generateAllCountrySubDivisionMenuItems(data);
  }, [data]);

  return <SelectField {...props} options={options} />;
};
