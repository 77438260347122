import { Option } from 'components/shared/ui/Option';
import { TFunction } from 'i18next';
import { Countries, CountrySubdivision } from 'services/location/locationTypes';

/**
 * This will convert a string Enum to a list of Options for a Select Form Control.
 * Enum needs to have a string value of the translation key.
 *
 * @param {any} anEnum A string enum class
 * @param {TFunction} translate Function to translate string value to display
 * @return {Option[]} Array of Options to be used in Select Form Control
 */
export const generateEnumMenuItems = (anEnum: { [p: number]: string }, translate: TFunction): Option[] => {
  return Object.keys(anEnum).map((key: any) => {
    return {
      label: translate(anEnum[key]),
      value: anEnum[key],
    };
  });
};

/**
 * This will convert the list of countries from a successful Location API call
 * to a list of Options for a Select Form Control.
 * After converting them to Options, it takes the corporate countries and puts them at the top of the list.
 *
 * @param {Country[]} countries array of countries retrieved from store
 * @return {Option[]} Array of Options to be used in Select Form Control
 */
export const generateAllCountryMenuItems = (countries: Countries): Option<string>[] => {
  return countries?.map((countryDetails) => {
    return {
      label: countryDetails.name ?? '',
      value: countryDetails.countryIso3Code ?? '',
    };
  });
};

/**
 * This will convert the list of subdivisions from a successful API call
 * to a list of Options for a Select Form Control.
 *
 * @param {CountrySubdivision[]} countrySubdivisions array of country subdivisions retrieved from store
 * @return {Option[]} Array of Options to be used in Select Form Control
 */
export const generateAllCountrySubDivisionMenuItems = (countrySubdivisions: CountrySubdivision[]): Option<string>[] => {
  return countrySubdivisions?.map((countrySubdivisions) => {
    return {
      label: countrySubdivisions.name ?? '',
      value: countrySubdivisions.abbreviation ?? '',
    };
  });
};
