import { Box, Paper, styled } from '@mui/material';

export const ResSummaryDrawerHeader = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 20px;
`;

export const SummaryContent = styled(Paper)`
  flex-grow: 0;
  height: 100%;
`;

export const DrawerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 360px;
`;
