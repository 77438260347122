import { useCallback } from 'react';
import {
  BranchDetails,
  BranchHierarchy,
  BranchId,
  BranchV2,
  Countries,
  CountrySubdivision,
} from 'services/location/locationTypes';

import {
  getBranchByPeopleSoftId,
  getBranchHierarchy,
  getCountries,
  getCountrySubdivisions,
  getMultipleBranchDetails,
} from 'services/location/locationService';
import { EhiErrors } from 'services/EhiErrors';
import { parseUrn } from 'utils/urnUtils';
import { isAllowedBranch } from 'utils/locationUtils';
import { useTranslations } from 'components/shared/i18n';

export type LocationServiceHook = {
  getCountriesCall: () => Promise<Countries>;
  getCountrySubdivisionsCall: (countryIso3Code: string) => Promise<Array<CountrySubdivision>>;
  getMultipleBranchDetailsCall: (locationIds: Array<string>) => Promise<BranchDetails[]>;
  getBranchByPeopleSoftIdCall: (locationId: string) => Promise<BranchV2>;
  getBranchHierarchyCall: (locationId: string) => Promise<BranchHierarchy>;
  getLocationByUrn: (urn: string | undefined) => Promise<BranchV2>;
};

export const useLocationService = (): LocationServiceHook => {
  const { t } = useTranslations();

  const getCountriesCall = useCallback(async (): Promise<Countries> => {
    return getCountries();
  }, []);

  const getCountrySubdivisionsCall = useCallback(
    async (countryIso3Code: string): Promise<Array<CountrySubdivision>> => {
      return getCountrySubdivisions(countryIso3Code);
    },
    []
  );

  const getMultipleBranchDetailsCall = useCallback(async (locationIds: Array<string>): Promise<BranchDetails[]> => {
    return getMultipleBranchDetails(locationIds);
  }, []);

  const getBranchByPeopleSoftIdCall = useCallback(async (branchId: BranchId): Promise<BranchV2> => {
    return getBranchByPeopleSoftId(branchId);
  }, []);

  const getBranchHierarchyCall = useCallback(async (locationId: string): Promise<BranchHierarchy> => {
    return getBranchHierarchy(locationId);
  }, []);

  /**
   * Gets full branch details by the location urn string that are marked as valid branch
   * @param {string | undefined} urn
   * @return {Promise<BranchV2>}
   */
  const getLocationByUrn = useCallback(
    async (urn: string | undefined): Promise<BranchV2> => {
      const branch = await getBranchByPeopleSoftId(parseUrn(urn));
      if (isAllowedBranch(branch)) {
        return branch;
      } else {
        // Throw an EhiError, so it behaves the same as service failure
        // eslint-disable-next-line no-throw-literal
        throw { errors: [{ localizedMessage: t('error.branchNotFound') }] } as EhiErrors;
      }
    },
    [t]
  );

  return {
    getCountriesCall,
    getCountrySubdivisionsCall,
    getMultipleBranchDetailsCall,
    getBranchByPeopleSoftIdCall,
    getBranchHierarchyCall,
    getLocationByUrn,
  };
};
