import { ReservationSummaryCard } from 'components/resSummary/ReservationSummaryCard';
import CardContent from '@mui/material/CardContent';
import { Grid } from '@mui/material';
import { Caption2 } from 'components/shared/ui/styles/Typography.styles';
import { Body1 } from '@ehi/ui';
import { useTranslations } from 'components/shared/i18n';
import { useAppSelector } from 'redux/hooks';
import { selectAddons } from 'redux/selectors/bookingEditor';

export const AddOnsSummary = (): JSX.Element => {
  const { t } = useTranslations();
  const addOns = useAppSelector(selectAddons);
  return (
    <ReservationSummaryCard title={t('reservationSummary.peo')} loading={false}>
      <CardContent>
        <Grid container spacing={2}>
          {addOns ? (
            <>
              <Grid item xs>
                <div>
                  <Caption2>{'ROADSIDE PLUS'}</Caption2>
                </div>
                <div>
                  <Caption2>{'SLP'}</Caption2>
                </div>
                <div>
                  <Caption2>{'SC REC - FL SURCHG RECOV'}</Caption2>
                </div>
                <div>
                  <Caption2>{'STATE TAX'}</Caption2>
                </div>
              </Grid>
              <Grid item xs>
                <div>
                  <Body1 bold display='inline'>
                    {'$5.99/Day'}
                  </Body1>
                </div>
                <div>
                  <Body1 bold display='inline'>
                    {'$13.31/Day'}
                  </Body1>
                </div>
                <div>
                  <Body1 bold display='inline'>
                    {'$2.00/Day'}
                  </Body1>
                </div>
                <div>
                  <Body1 bold display='inline'>
                    {'$11.72/Day'}
                  </Body1>
                </div>
              </Grid>
            </>
          ) : (
            <Grid item xs>
              <Caption2>No protections, products, or equipment selected</Caption2>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </ReservationSummaryCard>
  );
};
