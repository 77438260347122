import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { ReservationPageSpacingWrapper } from 'components/modify/ReservationPageSpacingWrapper';
import CustomerHeader from 'components/customerHeader/CustomerHeader';

const ReservationRouteWrapper: FunctionComponent = () => {
  return (
    <>
      <CustomerHeader />
      <ReservationPageSpacingWrapper>
        <Outlet />
      </ReservationPageSpacingWrapper>
    </>
  );
};

export default ReservationRouteWrapper;
