import { getDefaultAxiosInstance } from 'components/shared/axios/axiosConfiguration';
import { DriverProfile } from 'services/renter/driverProfile';
import { EHI_ACCEPT_PROTECTED_DATA, PROTECTED_DATA_VALUE } from 'services/headerConstants';

const ROOT_PATH = '/renter';
const DRIVER_PROFILE_PATH = '/driverProfile';

export const getDriverProfile = async (driverUuid: string): Promise<DriverProfile> => {
  const axiosInstance = getDefaultAxiosInstance();
  const response = await axiosInstance.get(`${ROOT_PATH}${DRIVER_PROFILE_PATH}/${driverUuid}`, {
    genericUrl: `${ROOT_PATH}${DRIVER_PROFILE_PATH}/{uuid}`,
    headers: {
      [EHI_ACCEPT_PROTECTED_DATA]: PROTECTED_DATA_VALUE,
    },
  });

  return response.data;
};
