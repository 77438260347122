import { ReactNode } from 'react';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLocale } from 'components/shared/i18n/index';

type DatePickerLocalizationProviderProps = {
  children: ReactNode;
};

export const DatePickerLocalizationProvider = ({ children }: DatePickerLocalizationProviderProps): JSX.Element => {
  const { locale } = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};
