import { Card, Grid } from '@mui/material';
import { BrandLogo } from 'components/shared/ui/logos/BrandLogo';
import { Brand } from 'utils/constants';

import { useTranslations } from 'components/shared/i18n';
import { parseUrn } from 'utils/urnUtils';
import { HeaderName, HeaderValue, StyledPaper } from 'components/customerHeader/CustomerHeader.styles';
import { useAppSelector } from 'redux/hooks';
import {
  selectBrand,
  selectCharges,
  selectReservationData,
  selectVehicleClassSelection,
} from 'redux/selectors/bookingEditor';
import { FunctionComponent } from 'react';
import { selectRenterFullName } from 'redux/selectors/driver';

// Export ID to easily use with StickyCardNavigation
export const CUSTOMER_HEADER_ELEMENT_ID = 'customer-header';

const CustomerHeader: FunctionComponent = () => {
  const { t } = useTranslations();
  const reservation = useAppSelector(selectReservationData);
  const brandId = useAppSelector(selectBrand);
  const vehicleClass = useAppSelector(selectVehicleClassSelection);
  const charges = useAppSelector(selectCharges);
  const renterFullName = useAppSelector(selectRenterFullName);
  const parsedBrand = brandId ? (parseUrn(brandId) as Brand) : Brand.ENTERPRISE;

  return (
    <StyledPaper id={CUSTOMER_HEADER_ELEMENT_ID}>
      <Card>
        <Grid container direction='row' alignItems={'center'}>
          <Grid container item xs={6} alignItems={'center'} spacing={1}>
            <Grid item>
              <BrandLogo brand={parsedBrand} height={40} width={40} />
            </Grid>
            <Grid item>
              <HeaderName> {renterFullName ? renterFullName : 'Dwayne Johnson'}</HeaderName>
            </Grid>
          </Grid>
          <Grid container item xs={6} justifyContent={'flex-end'} spacing={3}>
            <Grid item>
              <HeaderName sx={{ color: 'black' }}>{'27 days'}</HeaderName>
              <HeaderValue>{reservation ? parseUrn(vehicleClass?.reserved) : 'Economy'}</HeaderValue>
            </Grid>
            <Grid item sx={{ textAlign: 'right' }}>
              <HeaderName>${reservation ? charges?.totalCharges?.amount : '622.19'}</HeaderName>
              <HeaderValue sx={{ color: 'text.secondary' }}>{t('common.estimatedCharges')}</HeaderValue>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </StyledPaper>
  );
};
export default CustomerHeader;
