import Box from '@mui/material/Box';
import { styled } from '@mui/material';

export const STICKY_NAV_CLASS_NAME = 'make-it-stick';

export const DynamicBox = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  [`&.${STICKY_NAV_CLASS_NAME}`]: {
    borderLeft: theme.ehi.styles.border.light,
    borderRight: theme.ehi.styles.border.light,
    // just under rental header
    zIndex: 99,
    // match card width, minus 1px for border width
    width: `calc(100% - ${theme.spacing(11.4)} - 1px)`,
    // as const because weird typescript/styled-components things
    position: 'fixed' as const,
  },
}));

export const FlexStartItem = styled('div')`
  display: flex;
  flex: 1 1 0;
  justify-content: flex-start;
  align-items: center;
`;

export const FlexEndItem = styled('div')`
  display: flex;
  flex: 1 1 0;
  justify-content: flex-end;
  align-items: center;
`;

export const CardContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
