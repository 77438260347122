export interface DriverValues {
  lastName: string;
  firstName: string;
  age: number | undefined;
  country: string;
  streetAddress1: string;
  streetAddress2: string;
  state: string;
  city: string;
  postalCode: string;
  contactType: string;
  countryCode: string;
  phone: string;
}

export enum DriverFields {
  LastName = 'lastName',
  FirstName = 'firstName',
  Age = 'age',
  Country = 'country',
  StreetAddress1 = 'streetAddress1',
  StreetAddress2 = 'streetAddress2',
  State = 'state',
  City = 'city',
  PostalCode = 'postalCode',
  ContactType = 'contactType',
  CountryCode = 'countryCode',
  Phone = 'phone',
}
