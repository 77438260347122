import InputAdornment from '@mui/material/InputAdornment';
import { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import { StyledIconButton } from 'components/shared/ui/InputIconButton/InputIconButton.styles';

export type InputIconButtonProps = {
  icon: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  position?: 'start' | 'end';
  label?: string;
  disabled?: boolean;
};

export const InputIconButton: FunctionComponent<InputIconButtonProps> = ({
  onClick,
  icon,
  position = 'end',
  label,
  disabled,
}) => (
  <InputAdornment position={position}>
    <StyledIconButton size='medium' aria-label={label} onClick={onClick} disabled={disabled}>
      {icon}
    </StyledIconButton>
  </InputAdornment>
);
